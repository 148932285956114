import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Content } from '../../styles/globalStyles';
import { TitleBar } from '../../styles/layout/titlebar';
import AdvancedTabs from '../../components/website/advanced/advancedTabs/advancedTabs';
import { useSelector } from 'react-redux';
import { integrationSelector } from 'store/me/meSelectors';
import UserHelper from 'helpers/user';

const CustomerIndex = () => {
  const history = useHistory();
  const integrations = useSelector(integrationSelector);

  const customersCards = [
    {
      title: 'Client List',
      name: 'accounts',
      desc: 'Perform recurring charges, and track multiple charges, that are associated with the same client.',
      icon: 'customers',
      btnText: 'Manage',
    },
    {
      title: 'Stripe',
      name: 'stripe',
      desc: 'Easily and securely process online payments by connecting to your Stripe account.',
      icon: 'stripe',
      customComponentContent: integrations.stripe ? 'Disconnect' : 'Connect',
    },
    {
      title: 'Agency Plans',
      name: 'plans',
      desc: 'Define the base price, limits, and billing cycle for recurring usage of services.',
      icon: 'plans',
      btnText: 'Manage',
    },
    {
      title: 'Assigned Plans',
      name: 'subscriptions',
      desc: 'Charge a client on a recurring basis by assigning them to agency plans.',
      icon: 'subscriptions',
      btnText: 'Manage',
    },
    {
      title: 'Billing',
      name: 'billing',
      desc: 'Set up the billing day to charge clients, currency, and the tax rate to add on their invoices.',
      icon: 'billing',
      btnText: 'Manage',
    },
    {
      title: 'PDF Reports',
      name: 'client-reports',
      desc: `Manage your Client PDF Reports on generated to keep them informed about their website health.`,
      icon: 'pdf',
      btnText: 'Manage',
    },
    {
      title: 'Invoices',
      name: 'invoices',
      desc: 'View invoices owed by clients that are generated periodically from subscriptions.',
      icon: 'invoice',
      btnText: 'View',
    },
    {
      title: 'Payment Intents',
      name: 'payment-intents',
      desc: 'View the process of collecting a payment from your clients.',
      icon: 'paymentintent',
      btnText: 'View',
    },
    {
      title: 'Email Templates',
      name: 'email-templates',
      desc: 'Manage the email notification templates sent to your clients.',
      icon: 'emailTemplate',
      btnText: 'Manage',
    },
    UserHelper.hasAccessToBetaFeatures() ? {
      title: 'Custom SMTP',
      name: 'custom-smtp',
      desc: 'Send emails to your clients using your own SMTP server.',
      icon: 'dns',
      btnText: 'Manage',
    } : null,
    {
      title: 'Terms & Conditions',
      name: 'legal',
      desc: 'Manage the Terms & Conditions templates viewed by your clients.',
      icon: 'terms',
      btnText: 'Manage',
    },
  ].filter(Boolean);

  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    {
      text: 'Clients',
    },
  ];

  const handleOnClick = e => {
    if (e) {
      if (e === 'client-reports') {
        history.push({ pathname: `/reports/${e}`, });
      } else {
        history.push({ pathname: `/clients/${e}`, });
      }
    }
  };
  return (
    <Fragment>
      <TitleBar>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Clients</TitleBar.Title>
      </TitleBar>
      <Content className='customers-selections'>
        {customersCards.map((el, index) => {
          return (
            <AdvancedTabs
              key={index}
              type={el.type ? el.type : 'table'}
              name={el.name}
              desc={el.desc}
              icon={el.icon}
              title={el.title}
              btnText={el.btnText}
              customComponentContent={el.customComponentContent ? el.customComponentContent : null}
              onClick={e => handleOnClick(e)}
            />
          );
        })}
      </Content>
    </Fragment>
  );
};

export default CustomerIndex;
