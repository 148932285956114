import React, { Fragment } from 'react';
import env from 'config/env';
import JsxHelper from './jsx';
import Icon from 'components/layout/icon';
import { WPSForm } from 'styles/layout/forms';
import ArrayHelper from 'helpers/array';
import { isNullOrUndefined } from 'helpers';
import ObjectHelper from 'helpers/object';

const SUPPORTED_PLUGINS = ['wp-mail-smtp', 'wp-mail-smtp-pro']
const METHOD_TOOLTIP = `Select the SMTP method you want to use for sending emails. ${env.getBrandShortName()} uses the WP Mail SMTP plugin to apply the settings for the selected method.`;
const PLUGIN_NAME = 'WP Mail SMTP';
const PLUGIN_URL = 'https://wordpress.org/plugins/wp-mail-smtp/';
const GLOBAL_DISABLED_TOOLTIP = 'Global SMTP is currently disabled. To enable it, go to Global Settings > SMTP and apply a single SMTP configuration across all sites.';

const METHODS = [
  {name:'SendGrid',value:'sendgrid',icon:'sendgrid'},
  {name:'Custom SMTP',value:'custom',icon:'settings'},
  {name:'Global SMTP',value:'global',icon:'globalManage'},
  {name:'None',value:'none',icon:'offline'},
]

const getInputFields = (website) => ({
  plugin: {
    use_settings: {
      label: website
        ? 'Enable custom SMTP settings for this website'
        : 'Enable Global SMTP settings',
      type: 'checkbox',
      tooltip: website
        ? 'Check this box to apply the SMTP settings exclusively to this website. The settings will override the Global SMTP settings once saved.'
        : 'Check this box to apply the SMTP settings across all websites. Note that individual websites can override these global settings via their own settings.',
      default: false,
    },
    license_key: {
      label: 'License key',
      type: 'text',
      default: '',
      tooltip: 'Enter your license key to enable automatic updates and other premium features.',
      placeholder: 'Enter your license key (PRO version only)',
    },
  },
  mail: {
    from_email: {
      label: 'From email',
      tooltip: 'The email address which emails are sent from.',
      type: 'text',
      default: '',
    },
    from_name: {
      label: 'From name',
      tooltip: 'The name which emails are sent from.',
      type: 'text',
      default: '',
    },
    from_name_force: {
      label: 'Force from name',
      type: 'checkbox',
      default: true,
      tooltip: 'If checked, the From Name setting above will be used for all emails, ignoring values set by other plugins.',
    },
    from_email_force: {
      label: 'Force from email',
      type: 'checkbox',
      default: true,
      tooltip: 'If checked, the From Email setting above will be used for all emails, ignoring values set by other plugins.',
    },
    return_path: {
      label: 'Set the return-path to match the From email',
      type: 'checkbox',
      default: true,
      tooltip: 'Return path indicates where non-delivery receipts - or bounce messages - are to be sent. If unchecked, bounce messages may be lost. Some providers may ignore this option.',
    },
  },
  smtp: {
    autotls: {
      label: 'Auto-enable TLS encryption',
      type: 'checkbox',
      default: true,
      tooltip: 'If checked, the TLS encryption will automatically be enabled if a server supports it.',
    },
    auth: {
      label: 'Use SMTP authentication',
      type: 'checkbox',
      default: true,
      tooltip: 'If checked, the Username and Password will be used for SMTP authentication.',
    },
    host: {
      label: 'Host',
      type: 'text',
      default: '',
    },
    port: {
      label: 'Port',
      type: 'text',
      default: '',
    },
    encryption: {
      label: 'Encryption',
      type: 'select',
      options: [
        {
          text: 'SSL',
          value: 'ssl',
        },
        {
          text: 'TLS',
          value: 'tls',
        },
      ],
      default: null,
      tooltip: 'For most servers TLS is the recommended option. If your SMTP provider offers both SSL and TLS options, we recommend using TLS.',
    },
    user: {
      label: 'Username',
      type: 'text',
      default: '',
    },
    pass: {
      label: 'Password',
      type: 'text',
      default: '',
    },
  },
});

const buildUserConfig = (inputFields, initialConfig, defaultConfig) => {
  let smtpMailerConfig = initialConfig || null;
  if (isNullOrUndefined(smtpMailerConfig)) {
    smtpMailerConfig = defaultConfig;
  }
  // Fill default fields
  let formData = ObjectHelper.clone(smtpMailerConfig || {});
  ['plugin', 'mail', 'smtp'].forEach(group => {
    if (!formData[group]) {
      formData[group] = {};
    }
    // Fill default fields
    for (const key in inputFields[group]) {
      const data = inputFields[group][key];
      if (!ObjectHelper.hasKey(formData[group], key)) {
        formData[group][key] = ObjectHelper.hasKey(data, 'default') ? data.default : '';
      }
    }
    // Delete fields that do not exist in inputFields
    for (const key in formData[group]) {
      if (!ObjectHelper.hasKey(inputFields[group], key)) {
        delete formData[group][key];
      }
    }
  });
  delete formData.provider;
  delete formData.updated_at;
  delete formData.updated_by;
  return formData;
}

const isPluginActive = (website) =>
  website && website.active_plugins && website.active_plugins.some(p => SUPPORTED_PLUGINS.includes(p))

const isProPluginActive = (website) =>
  website && website.active_plugins && website.active_plugins.includes('wp-mail-smtp-pro')

const renderMethodSelectOptions = (selected, onSelect, disables) => 
  <WPSForm.Fieldset className='smtp-mailer-options' style={{ marginTop: '0px' }}>
    <legend>SMTP Method</legend>
    <WPSForm.RowItem>
      <div className='display-flex'>
        {JsxHelper.createLabelWithTooltip('Choose your SMTP method', METHOD_TOOLTIP)}
      </div>
      <div className='flags-container'>
        {Object.keys(METHODS).map((key) => {
          const method = METHODS[key];
          const disabled = disables && disables[method.value];
          const disableTooltip = disabled ? disables[method.value] : null;
          return (
            <div className='flag-item' key={key} data-for={method.value} data-tip={disableTooltip}>
              <Icon tag={method.icon} />
              <label className={disabled ? 'disabled' : ''} htmlFor={method.value}>{method.name}</label>
              <input
                name={method.value}
                type='radio'
                checked={selected === method.value}
                disabled={disabled}
                onChange={() => onSelect(method.value)}
              />
              {disableTooltip && JsxHelper.createTooltip(method.value, disableTooltip)}
            </div>
          );
        })}
      </div>
    </WPSForm.RowItem>
  </WPSForm.Fieldset>

const renderInstallPlugin = (onClick, loading, classes, nextBtnHandler) =>
  <Fragment>
  <p className={`color-primary ${classes||''}`}>
    Manage this site's SMTP settings directly from {env.getBrandShortName()} by installing and activating the{' '}
    <a className='subheader-link' href={PLUGIN_URL} target='_blank' rel='noopener noreferrer'>WP Mail SMTP</a>{' '}
    plugin. Simply click the button below to get started.

  <div className='margin-top-24' style={{display: 'flex'}}>
    {JsxHelper.createButton({ label: 'Install & Activate', onClick, loading, classes: 'success--btn' })}
    {nextBtnHandler && JsxHelper.createButton({ label: 'Next', onClick: nextBtnHandler, disabled: loading, classes: 'margin-left-6' })}
  </div>
  </p>
</Fragment>

const renderCustomFormFields = (inputFields, userConfig, handleOnChange) => 
  <div className='smtp-custom'>
    <WPSForm.Fieldset>
      <legend>SMTP</legend>
      <WPSForm.Row>
        <WPSForm.RowItem>
          {JsxHelper.createTextInput({
            name: 'host',
            label: inputFields.smtp.host.label,
            value: userConfig.smtp.host || '',
            onChange: e => handleOnChange(e, 'smtp'),
          })}
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          {JsxHelper.createSelectInput({
            name: 'encryption',
            label: inputFields.smtp.encryption.label,
            value: userConfig.smtp.encryption || '',
            options: ArrayHelper.buildSelectOptions(
              inputFields.smtp.encryption.options,
              'text',
              'value',
            ),
            onChange: e => handleOnChange(e, 'smtp'),
          })}
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          {JsxHelper.createTextInput({
            name: 'port',
            label: inputFields.smtp.port.label,
            value: userConfig.smtp.port || '',
            onChange: e => handleOnChange(e, 'smtp'),
          })}
        </WPSForm.RowItem>
      </WPSForm.Row>
      {userConfig.smtp.auth && (
        <WPSForm.Row>
          <WPSForm.RowItem>
            {JsxHelper.createTextInput({
              name: 'user',
              label: inputFields.smtp.user.label,
              value: userConfig.smtp.user || '',
              onChange: e => handleOnChange(e, 'smtp'),
            })}
          </WPSForm.RowItem>
          <WPSForm.RowItem>
            {JsxHelper.createTextInput({
              name: 'pass',
              label: inputFields.smtp.pass.label,
              value: userConfig.smtp.pass || '',
              onChange: e => handleOnChange(e, 'smtp'),
            })}
          </WPSForm.RowItem>
        </WPSForm.Row>
      )}
      {!inputFields.smtp.auth.hide && !inputFields.smtp.autotls.hide && <WPSForm.Row className='checkboxes-row'>
        <WPSForm.RowItem>
          {JsxHelper.createCheckbox({
            name: 'auth',
            label: inputFields.smtp.auth.label,
            checked: userConfig.smtp.auth || false,
            onChange: e => handleOnChange(e, 'smtp'),
            tooltip: inputFields.smtp.auth.tooltip,
          })}
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          {JsxHelper.createCheckbox({
            name: 'autotls',
            label: inputFields.smtp.autotls.label,
            checked: userConfig.smtp.autotls || false,
            onChange: e => handleOnChange(e, 'smtp'),
            tooltip: inputFields.smtp.autotls.tooltip,
          })}
        </WPSForm.RowItem>
      </WPSForm.Row>}
    </WPSForm.Fieldset>
    <WPSForm.Fieldset>
      <legend>Mail</legend>
      <WPSForm.Row>
        <WPSForm.RowItem>
          {JsxHelper.createTextInput({
            name: 'from_email',
            label: inputFields.mail.from_email.label,
            value: userConfig.mail.from_email || '',
            onChange: e => handleOnChange(e, 'mail'),
            tooltip: inputFields.mail.from_email.tooltip,
          })}
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          {JsxHelper.createTextInput({
            name: 'from_name',
            label: inputFields.mail.from_name.label,
            value: userConfig.mail.from_name || '',
            onChange: e => handleOnChange(e, 'mail'),
            tooltip: inputFields.mail.from_name.tooltip,
          })}
        </WPSForm.RowItem>
      </WPSForm.Row>
      {!inputFields.mail.from_email_force.hide && !inputFields.mail.from_name_force.hide && <WPSForm.Row className='checkboxes-row'>
        <WPSForm.RowItem >
          {JsxHelper.createCheckbox({
            name: 'from_email_force',
            label: inputFields.mail.from_email_force.label,
            checked: userConfig.mail.from_email_force || false,
            onChange: e => handleOnChange(e, 'mail'),
            tooltip: inputFields.mail.from_email_force.tooltip,
          })}
        </WPSForm.RowItem>
        <WPSForm.RowItem>
          {JsxHelper.createCheckbox({
            name: 'from_name_force',
            label: inputFields.mail.from_name_force.label,
            checked: userConfig.mail.from_name_force || false,
            onChange: e => handleOnChange(e, 'mail'),
            tooltip: inputFields.mail.from_name_force.tooltip,
          })}
        </WPSForm.RowItem>
      </WPSForm.Row>}
      {!inputFields.mail.return_path.hide && <WPSForm.Row className='checkboxes-row'>
        <WPSForm.RowItem>
          {JsxHelper.createCheckbox({
            name: 'return_path',
            label: inputFields.mail.return_path.label,
            checked: userConfig.mail.return_path || false,
            onChange: e => handleOnChange(e, 'mail'),
            tooltip: inputFields.mail.return_path.tooltip,
          })}
        </WPSForm.RowItem>
      </WPSForm.Row>}
    </WPSForm.Fieldset>
  </div>

const renderPluginFields = (inputFields, userConfig, handleOnChange, showUseSettings, showLicense) =>
  (showUseSettings || showLicense) && <WPSForm.Fieldset>
    <legend>Plugin</legend>
    {showUseSettings && <WPSForm.Row className='checkboxes-row'>
      <WPSForm.RowItem>
        {JsxHelper.createCheckbox({
          name: 'use_settings',
          style: { width: '100%' },
          label: inputFields.plugin.use_settings.label,
          checked: userConfig.plugin.use_settings || false,
          onChange: e => handleOnChange(e, 'plugin'),
          tooltip: inputFields.plugin.use_settings.tooltip,
        })}
      </WPSForm.RowItem>
    </WPSForm.Row>}
    {showLicense && <WPSForm.Row>
      <WPSForm.RowItem>
        {JsxHelper.createTextInput({
          name: 'license_key',
          label: inputFields.plugin.license_key.label,
          value: userConfig.plugin.license_key || '',
          onChange: e => handleOnChange(e, 'plugin'),
          placeholder: inputFields.plugin.license_key.placeholder,
          tooltip: inputFields.plugin.license_key.tooltip,
        })}
      </WPSForm.RowItem>
    </WPSForm.Row>}
  </WPSForm.Fieldset>

const renderSPFBox = (dispatch) => 
  <WPSForm.Fieldset className='spf-box'>
    <legend>SPF Record</legend>
    <p>
    To improve email deliverability and avoid spam filters, it's essential to configure an SPF (Sender Policy Framework) record, which helps mail servers verify emails from your domain.
    </p>
    <p>
    Make sure your DNS SPF record authorizes your SMTP server and includes {JsxHelper.createCopyButton({
      label: env.getBrandMailServer(),
      style: { display: 'inline-block', margin: '0 3px' },
      type: 'DNS record',
      dispatch,
    })} as a valid sender.
    </p>
    <p>
      Example: <span className='example-background' style={{padding: '5px'}}>
        v=spf1 include:_spf.mailgun.org a:{env.getBrandMailServer()} ~all
      </span>
    </p>
    <p>
      You can check if your SPF record is properly configured {JsxHelper.createToolboxLink('', 'MXToolbox')}.
    </p>
  </WPSForm.Fieldset>

const renderGlobalSettingsBox = (available, enabled, inuse, goToGlobalSettings) => {
  let jsx = null;
  const goToJSX = <span onClick={goToGlobalSettings} className='box-link'>Global Settings</span>;
  const goToEnableJSX = <Fragment>To enable them, please go to the {goToJSX} page.</Fragment>
  const style = { margin: '0' };
  if (available) {
    if (inuse) {
      jsx = enabled
        ? JsxHelper.createSuccessBox('Global SMTP settings applied.', style)
        : JsxHelper.createWarningBox(<Fragment>Global settings are configured for this website, but they are currently disabled. {goToEnableJSX}</Fragment>, style);
    } else if (enabled) {
      jsx = JsxHelper.createTipBox('Global SMTP settings will be applied to this website.', style);
    } else {
      jsx = JsxHelper.createWarningBox(<Fragment>Global settings will not take effect until they are enabled. {goToEnableJSX}</Fragment>, style);
    }
  }
  return <div className='margin-top-24 margin-bottom-0'>{jsx}</div>;
}

const renderNoneBox = (inuse) => {
  let jsx = null;
  const style = { margin: '0' };
  if (!inuse) {
    jsx = JsxHelper.createTipBox('No SMTP settings will be applied for this website.', true, '')
  } else {
    jsx = JsxHelper.createSuccessBox('No SMTP settings are applied for this website.', style);
  }
  return <div className='margin-top-24 margin-bottom-0'>{jsx}</div>;
}

const SMTPHelper = {
  PLUGIN_NAME,
  PLUGIN_URL,
  METHODS,
  GLOBAL_DISABLED_TOOLTIP,
  isPluginActive,
  isProPluginActive,
  renderInstallPlugin,
  renderCustomFormFields,
  renderPluginFields,
  renderMethodSelectOptions,
  renderGlobalSettingsBox,
  renderNoneBox,
  getInputFields,
  buildUserConfig,
  renderSPFBox,
};

export default SMTPHelper;

