import React, { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { isEmptyOrNull } from 'helpers';
import { useDispatch } from 'react-redux';
import { deleteWebsite, updateWebsiteLabel, fetchWebsite } from 'store/website/websiteActions';
import { setGlobalSuccessMsg, setGlobalPleaseWaitMsg } from 'store/global/globalActions';
import useConfirm from 'hooks/useConfirm';
import UserHelper from 'helpers/user';
import WebsiteHelper from 'helpers/website';
import { Container } from 'styles/layout/tables';
import WPSDataTable from '../../components/wpstaq/WPSDataTable/WPSDataTable';
import TableHelper from 'helpers/table';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import { useForm } from 'react-hook-form';
import useModal from 'hooks/useModal';
import './website.css';

const TableView = ({ websites, loading }) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [modal, setModal] = useState(false);
  const modalDialog = useModal();
  const [loadingWhileLogin, setLoadingWhileLogin] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [labelText, setLabelText] = useState(false);
  const { register, errors, handleSubmit } = useForm({ reValidateMode: 'onSubmit' });

  const viewWebsiteProfile = slug => `${path}/${slug}/overview`;
  const openWebsiteProfile = (slug, page) => history.push(`${path}/${slug}/${page || 'overview'}`);

  const handleDeleteWebsite = website => {
    dispatch(setGlobalPleaseWaitMsg({ id: WebsiteHelper.getLabel(website), model: 'website', action: 'deleted' }));
    dispatch(deleteWebsite(website)).then(() => dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'website', action: 'deleted' })));
  };

  const onLabelTextChange = e => setLabelText(e.target.value);
  const handleEditLabel = () => {
    setModalLoading(true);
    const website = WebsiteHelper.findWebsite(websites, modal);
    const data = { website_slug: website.slug, label: labelText };
    dispatch(updateWebsiteLabel(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'website label', action: 'updated' }))
        setModal(false);
      }).finally(() => setModalLoading(false)); 
  }

  const onRefreshWebsite = (website) => {
    const data = {
      website_slug: website.slug,
      check_status: true,
      check_hosting_provider: true
    }
    dispatch(setGlobalPleaseWaitMsg({ id: WebsiteHelper.getLabel(website), model: 'website', action: 'refreshed' }));
    dispatch(fetchWebsite(data))
    .then(() => dispatch(setGlobalSuccessMsg({ id: WebsiteHelper.getLabel(website), model: 'website', action: 'refreshed' })))
  }

  const actions = [
    {
      value: 'Edit Label',
      onClick: website => {
        setLabelText(WebsiteHelper.getLabel(website));
        setModal(website.slug);
      },
    },
    {
      value: 'Settings',
      onClick: website => history.push({ pathname: viewWebsiteProfile(website.slug) }),
    },
    {
      value: 'View',
      onClick: website => window.open(`https://${website.default_domain}`, '_blank'),
    },
    {
      value: 'Refresh',
      onClick: onRefreshWebsite,
    },
    {
      value: 'Delete',
      doHide: item => !UserHelper.hasPermissions(`website:delete:${item.slug}`),
      onClick: website => DialogHelper
        .confirmDelete(confirm, WebsiteHelper.getLabel(website), 'website')
        .then(() => handleDeleteWebsite(website)),
    },
  ];

  const getColumnWidth = selector => {
    return {
      website: '30%',
      state: '5%',
      status: '5%',
      cdns: '5%',
      site_cached_by: '5%',
      redis_status: '5%',
      domains_ssl: '5%',
      alert: '5%',
      is_live: '8%',
      wp_version: '8%',
      php_version: '8%',
      actions: '11%',
    }[selector];
  };

  // eslint-disable-next-line
  const customColumns = [
    {
      name: 'Website',
      selector: 'slug',
      sortable: true,
      searchable: true,
      width: getColumnWidth('website'),
      cell: row => {
        const searchable = `${WebsiteHelper.getLabel(row)} ${row.default_domain} ${UserHelper.isAdminOrAgent() ? `${row.server_slug} ${row.partner_slug} ${row.database_name}` : ''}`;
        row = TableHelper.customizeCellValue(row, 'slug', searchable);
        return (
          <div>
            <span className='cell-header class-link profile-link' onClick={() => openWebsiteProfile(row.slug)}>{WebsiteHelper.getLabel(row)}</span>
            {WebsiteHelper.createOneClickLoginBubble(row, loadingWhileLogin, setLoadingWhileLogin, dispatch, modalDialog)}
            {WebsiteHelper.createLink(row, 'inactive-font-color cell-subheader padding-right-12 break-all')}
            {UserHelper.isAdminOrAgent() && <span className='cell-subheader' style={{ marginTop: '5px' }}>{row.server_slug} / {row.partner_slug}</span>}
          </div>
        );
      },
    },
    {
      name: 'State',
      selector: 'state',
      width: getColumnWidth('state'),
      cell: row => {
        const style = WebsiteHelper.getStateStyle(row.state);
        return JsxHelper.createIcon({
          color: style.color,
          icon: style.icon,
          tooltip: style.text,
          customClass: 'state-cell',
        })
      },
    },
    {
      name: 'Status',
      selector: 'status',
      width: getColumnWidth('status'),
      cell: row => {
        const style = WebsiteHelper.getStatusStyle(row.status, row.status_reason);
        return JsxHelper.createIcon({
          color: style.color,
          icon: style.icon,
          tooltip: style.text,
          customClass: 'status-cell',
        });
      },
    },
    {
      name: 'CDN',
      selector: 'cdns',
      width: getColumnWidth('cdns'),
      cell: row => {
        const cdn = WebsiteHelper.getCdnCache(row) || WebsiteHelper.getCdnProxy(row) || WebsiteHelper.getCloudflareZone(row);
        const status = WebsiteHelper.getCdnStatus(cdn);
        row = TableHelper.customizeCellValue(row, 'cdns', status.filter);
        return JsxHelper.createIcon({
          color: status.color,
          icon: status.icon,
          tooltip: status.text,
          customClass: `cdn-cell ${status.customClass}`,
        });
      },
    },
    {
      name: 'Cache',
      selector: 'site_cached_by',
      width: getColumnWidth('site_cached_by'),
      cell: row => {
        const cached = row.site_cached_by !== null;
        let tooltip = 'No cache';
        let color = 'inactive';
        if (cached) {
          tooltip = row.site_cached_by;
          color = 'success';
        }
        row = TableHelper.customizeCellValue(row, 'site_cached_by', tooltip);
        return JsxHelper.createIcon({
          color,
          icon: 'cache',
          tooltip,
          customClass: 'cache-cell',
        });
      },
    },
    {
      name: 'Redis',
      selector: 'objectcache_config',
      width: getColumnWidth('redis_status'),
      cell: row => {
        const config = row.objectcache_config;
        let tooltip = 'Not configured';
        let color = 'inactive';
        let status = 'Disabled';
        if (!isEmptyOrNull(config) && config.enabled === 'yes') {
          tooltip = `Enabled with max storage of ${config.max_storage}`
          status = 'Enabled';
          color = 'success';
        }
        TableHelper.customizeCellValue(row, 'objectcache_config', status);
        return JsxHelper.createIcon({
          color,
          icon: 'redis',
          tooltip,
          customClass: 'redis-cell',
        });
      },
    },
    {
      name: 'SSL',
      selector: 'domains_ssl',
      width: getColumnWidth('domains_ssl'),
      cell: row => {
        const details = WebsiteHelper.parseSSL(row.domains, row.domains_ssl);
        TableHelper.customizeCellValue(row, 'domains_ssl', details.tooltip);
        return JsxHelper.createIcon({
          color: details.modalParams.type,
          icon: details.icon,
          tooltip: details.tooltip,
          customClass: 'ssl-cell',
        });
      },
    },
    {
      name: 'Alerts',
      selector: 'alert',
      width: getColumnWidth('alert'),
      sortable: true,
      sortFunction: (a, b) => WebsiteHelper.getAlerts(a).rank - WebsiteHelper.getAlerts(b).rank,
      cell: row => {
        TableHelper.customizeCellValue(row, 'alert', WebsiteHelper.getAlertLevel(row));
        return WebsiteHelper.createAlertIcon(history, row, 'alert-cell');
      },
    },
    {
      name: 'Environment',
      selector: 'env',
      width: getColumnWidth('is_live'),
      sortable: true,
      cell: row => {
        const envName = WebsiteHelper.getEnvName(row);
        const envId = WebsiteHelper.getEnvId(row);
        TableHelper.customizeCellValue(row, 'env', envName);
        return JsxHelper.createIcon({
          icon: `${envId}Site`,
          tooltip: envName,
          customClass: `env-cell env-cell-${envId}`
        });
      },
    },
    JsxHelper.createTableTextHeaderWithCallback(
      'wp_version',
      'WP Version',
      getColumnWidth('wp_version'), 
      (row) => <span className='wp-version'>{row.wp_version}</span>,
      false
    ),
    JsxHelper.createTableTextHeaderWithCallback(
      'php_version',
      'PHP Version',
      getColumnWidth('php_version'), 
      (row) => <span className='php-version'>{row.php_version}</span>,
      false
    ),
    UserHelper.isAdminOrAgent() ? JsxHelper.createTableHiddenHeader('partner_slug', 'Partner') : null,
    UserHelper.isAdminOrAgent() ? JsxHelper.createTableHiddenHeader('server_slug', 'Server') : null, // used for filter only
    JsxHelper.createTableActionsHeader(actions, getColumnWidth('actions')),
  ].filter(Boolean);

  return (
    <Container id="websites-table" style={{overflow: 'inherit' }}>
      <WPSDataTable name='websites' columns={customColumns} body={websites} loading={loading} preserveViewPreferences={true} />
      {modal && DialogHelper.inputs({
        title: 'Edit Website Label',
        icon: 'edit',
        iconColor: 'success',
        btnText: 'Edit',
        onConfirm: handleSubmit(handleEditLabel),
        onClose: () => setModal(false),
        loading: modalLoading,
        register,
        inputs: [{
          name: 'label',
          label: 'New Label',
          placeholder: `Enter new label for "${modal}"`,
          value: labelText,
          onChange: onLabelTextChange,
          errors,
          required: true,
          minLength: 5,
        }]
      })}
    </Container>
  );
};

export default TableView;
