

import React, { useState, Fragment, useEffect } from 'react';
import { Container } from 'styles/website/profile';
import { WPSButton } from 'styles/layout/buttons';
import { useSelector, useDispatch } from 'react-redux';
import { globalPluginsSelector, globalThemesSelector } from 'store/globalPackage/globalPackageSelector';
import { partnerPluginsSelector, partnerThemesSelector } from 'store/partnerPackage/partnerPackageSelector';
import { globalAutoUpdaterConfig } from 'store/setting/settingSelectors';
import { isEmpty, isEmptyOrNull, isNullOrUndefined, getErrorMsg } from 'helpers';
import { TitleBar } from 'styles/layout/titlebar';
import SavePackage from 'components/package/save';
import {
  activateWebsitePlugin,
  deactivateWebsitePlugin,
  deletePrivatePackage,
  installPackage,
  uninstallPackage,
  listInstalledPackages,
  setPackageAutoUpdate,
  updateActiveTheme,
} from 'store/website/websiteActions';
import {
  setGlobalSuccessMsg,
  setGlobalPleaseWaitMsg,
  setGlobalInfoMsg,
  setGlobalWarningMsg,
  setGlobalErrorMsg,
} from 'store/global/globalActions';
import PackageService from 'services/package';
import useConfirm from 'hooks/useConfirm';
import { useHistory } from 'react-router-dom';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import useDidMountEffect from 'hooks/useDidMountEffect';
import WPSDataTable from 'components/wpstaq/WPSDataTable/WPSDataTable';
import PackageHelper from 'helpers/package';
import DialogHelper from 'helpers/dialog';
import useModal from 'hooks/useModal';
import StringHelper from 'helpers/string';
import ArrayHelper from 'helpers/array';
import UserHelper from 'helpers/user';
import ObjectHelper from 'helpers/object';
import JsxHelper from 'helpers/jsx';
import 'components/package/packages.css';

const Packages = ({ type, website, refresh, setCustomBackBtn }) => {
  const typeCapitalized = StringHelper.capitalizeFirstLetter(type);
  useTitle(`Website ${typeCapitalized}s`);
  const dispatch = useDispatch();
  const modalDialog = useModal();
  const history = useHistory();
  const confirm = useConfirm();
  const autoUpdaterConfig = useSelector(globalAutoUpdaterConfig);
  const isPlugin = type === 'plugin';
  const [websitePackages, setWebsitePackages] = useState([]);
  const [upload, setUpload] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [modalSettings, setModalSettings] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updaterStatus, setUpdaterStatus] = useState([]); // Status of the updated packages when clicking "Update All"
  const [updatedCount, setUpdatedCount] = useState(0); // Number of packages that have been updated
  const [updatesQueue, setUpdatesQueue] = useState([]); // Queue of packages to be updated
  const [updatingAll, setUpdatingAll] = useState(false); // Whether all packagess are being updated.
  const [loadingActive, setLoadingActive] = useState(false); // Checks if there is an activate/deactivate action in progress
  const [loadingAutoUpdate, setLoadingAutoUpdate] = useState(false); // Checks if there is an auto update action in progress
  const [manageUpdatesMode, setManageUpdatesMode] = useState(false); // If true, then the user is managing updates
  const [installedPackages, setInstalledPackages] = useState(false);
  const globalPackages = useSelector(isPlugin ? globalPluginsSelector : globalThemesSelector);
  let partnerPackages = useSelector(isPlugin ? partnerPluginsSelector : partnerThemesSelector);
  if (UserHelper.isAdminOrAgent()) {
    partnerPackages = partnerPackages.filter(item => item.partner_slug === website.partner_slug);
  }

  // --------------------------------------------------------
  // LIFECYCLE METHODS
  // --------------------------------------------------------

  useEffect(() => {
    if (isEmpty(websitePackages)) {
      softRefreshInstalledPackages();
    }
    return () => {
      setCustomBackBtn(false);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setUpload(false);
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    if (updatingAll) {
      updateQueueItem();
    }
    // eslint-disable-next-line
  }, [updatesQueue]);

  useEffect(() => {
    if (!upload) {
      setCustomBackBtn(false);
    }
    // eslint-disable-next-line
  }, [upload]);

  useDidMountEffect(() => {
    // Switch back to the plugins table after a successful private plugin upload
    setUpload(false);
    // If a private package was updated, then update the table data entry.
    if (!isEmptyOrNull(websitePackages)) {
      initPackagesTable();
    }
    // eslint-disable-next-line
  }, [website.private_packages]);

  // --------------------------------------------------------
  // NAVIGATION METHODS
  // --------------------------------------------------------

  const goToGlobalPackages = () => history.push({ pathname: `/${type}s`, });

  // --------------------------------------------------------
  // GIT METHODS
  // --------------------------------------------------------

  const copyWebhookURL = async (res) =>
    JsxHelper.copyToClipboard(dispatch, res.webhook_api_url, 'Webhook URL');

  const requestGitWebhookURL = async (_package) => {
    PackageService.getWebhookUrl({ package_slug: _package.slug })
                  .then(copyWebhookURL)
                  .catch(err => dispatch(setGlobalErrorMsg(err)))
  }

  const goToConnectGitPackages = () => {
    history.push({
      pathname: `/${type}s/git-connect`,
      search: `website=${website.slug}`,
    });
  };

  const goToGitEvents = (_package) => {
    history.push({
      pathname: `/${type}s/git-events`,
      state: _package
    });
  };

  // --------------------------------------------------------
  // API METHODS
  // --------------------------------------------------------

  const hardRefreshInstalledPackages = () => fetchInstalledPackages(false, false, true);
  const softRefreshInstalledPackages = (silent) => fetchInstalledPackages(true, silent, false);

  const fetchInstalledPackages = (soft, silent, hard) => {
    const data = {
      website_slug: website.slug,
      type: type,
      soft_sync: soft || false, // Refresh ERM cache only.
      full_sync: hard || false  // Refresh website cache.
    };
    !silent && setTableLoading(true);
    dispatch(listInstalledPackages(data))
      .then((installed) => {
        setInstalledPackages(installed);
        initPackagesTable(installed, true);
      }).finally(() => !silent && setTableLoading(false));
  }

  const refreshAfterPackageChange = (action, folderName) => {
    if (action === 'delete') {
      setWebsitePackages(prev => prev.filter(p => p.folder_name !== folderName));
      softRefreshInstalledPackages(true); // silent
    } else {
      softRefreshInstalledPackages();
    }
  }

  const initPackagesTable = (_installedPackages, sort) => {
    _installedPackages = _installedPackages || installedPackages; // If no packages were provided, then use the state.
    const normalized = PackageHelper.normalize(type, website, _installedPackages, globalPackages, partnerPackages).map(p => ({
      ...p,
      _from: p.version,
      _to: p.latest_version,
      url: p.latest_version_url,
    }));
    setWebsitePackages(sort
        ? PackageHelper.sortPackages(normalized)
        : normalized
    ); // Sort first time to avoid re-positioning active packages.
  }

  const runPackageUpdater = (item, callback) => {
    // If a callback was provided, then we want to ignore the notification and close the modal.
    const silent = !isEmptyOrNull(callback);
    if (!silent) {
      dispatch(setGlobalPleaseWaitMsg({ model: type, id: item.display_name, action: 'updated' }));
      closeModal();
    }
    // If the package is managed, do not allow the update.
    if (PackageHelper.isManaged(item)) {
      dispatch(setGlobalInfoMsg(`${typeCapitalized} cannot be updated from here.`));
      return;
    }
    // Call the API to update the package.
    const data = {
      website_slug: website.slug,
      type: item.type,
      src: item.latest_version_url,
      folder_name: item.folder_name,
    };
    setUpdateLoading(true);
    dispatch(installPackage(data))
      .then((_package) => {
        !silent && dispatch(setGlobalSuccessMsg({ model: type, id: item.display_name, action: 'updated' }));
        callback && callback(null, _package);
        !silent && refreshAfterPackageChange('update', item.folder_name);
      }).catch(err => {
        callback && callback(getErrorMsg(err));
        !silent && dispatch(setGlobalErrorMsg(err));
      }).finally(() => setUpdateLoading(false));
  }

  const activateTheme = (item, force) => {
    setLoadingActive(item.slug);
    const data = {
      website_slug: website.slug,
      theme_slug: item.scope === 'wordpress' ? null : item.slug,
      folder_name: item.folder_name,
      force: force || false
    };
    dispatch(updateActiveTheme(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'theme', id: item.display_name, action: 'activated' })))
      .finally(() => {
        closeModal();
        setModalLoading(false);
        setLoadingActive(null);
      });
  };

  const activatePlugin = (item, el) => {
    setLoadingActive(item.slug);
    const data = {
      website_slug: website.slug,
      plugin_slug: item.scope === 'wordpress' ? null : item.slug,
      folder_name: item.folder_name,
    };
    dispatch(activateWebsitePlugin(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'plugin', id: item.display_name, action: 'activated' })))
      .finally(() => {
        setLoadingActive(false);
        if (el && el.classList) {
          el.classList.remove('loadingspinner');
        }
      });
  };

  const deactivatePlugin = (item, force, el) => {
    setLoadingActive(item.slug);
    let data = {
      website_slug: website.slug,
      plugin_slug: item.scope === 'wordpress' ? null : item.slug,
      folder_name: item.folder_name,
      force: force
    };
    dispatch(deactivateWebsitePlugin(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'plugin', id: item.display_name, action: 'deactivated' })))
      .finally(() => {
        closeModal();
        setLoadingActive(false);
        if (el && el.classList) {
          el.classList.remove('loadingspinner');
        }
      });
  };

  const reinstallManagedPackage = item => {
    dispatch(setGlobalPleaseWaitMsg({ model: type, action: 'reinstalled' }));
    const data = {
      website_slug: website.slug,
      package_slug: item.slug,
    };
    dispatch(installPackage(data)).then(() => {
      dispatch(setGlobalSuccessMsg({ model: type, id: item.display_name, action: 'reinstalled' }));
      refreshAfterPackageChange('reinstall');
    });
  }

  const deletePackage = item => {
    dispatch(setGlobalPleaseWaitMsg({ model: type, action: 'deleted' }));
    if (item.is_git) {
      const _item = findPrivatePackage(item);
      dispatch(deletePrivatePackage(_item)).then(() => {
        dispatch(setGlobalSuccessMsg({ id: _item.display_name, model: type, action: 'deleted' }));
      });
    } else if (PackageHelper.isUnmanaged(item)) {
      const data = {
        website_slug: website.slug,
        type: type,
        folder_name: item.folder_name,
      };
      dispatch(uninstallPackage(data)).then(() => {
        dispatch(setGlobalSuccessMsg({ id: item.display_name, model: type, action: 'deleted' }));
        refreshAfterPackageChange('delete', item.folder_name);
      });
    } else {
      window.logHelper.error(`Failed to delete ${type} due to unexpected scope.`, item);
    }
  };

  const setAutoUpdate = (item, checked) => {
    const data = {
      website_slug: website.slug,
      type: type,
      folder_name: item.folder_name,
      auto_update: checked,
      scope: item.scope
    };
    setLoadingAutoUpdate(item.slug);
    dispatch(setPackageAutoUpdate(data))
      .then(() => {
        dispatch(setGlobalSuccessMsg({ model: type, id: item.display_name, action: checked ? 'enabled' : 'disabled' }));
        closeModal();
      })
      .finally(() => {
        setLoadingAutoUpdate(false);
        setModalLoading(false);
      });
  }

  // --------------------------------------------------------
  // UPLOADER METHODS
  // --------------------------------------------------------

  const openPackageUploader = (item) => {
    setCurrentPackage(item);
    setUpload(true);
  }

  // Get the selected package for udpate.
  const findPrivatePackage = (item) => {
    if (isNullOrUndefined(website.private_packages)) {
      window.logHelper.error('Failed to find private packages when trying to find a plugin.', item);
      return null;
    }
    return ArrayHelper.find(website.private_packages, 'slug', item.slug);
  };

  // --------------------------------------------------------
  // UPDATE MANAGER
  // --------------------------------------------------------
  const updateAvailableUpdates = () =>
    websitePackages.filter(p => p.latest_version && p.version !== p.latest_version).length > 0;

  const enableManageUpdatesMode = () =>
    PackageHelper.setManageUpdatesMode(website, type, setUpdaterStatus, setManageUpdatesMode, websitePackages, modalDialog);

  const disableManageUpdatesMode = () => {
    // Refresh the packages info if any package was updated.
    if (!isEmpty(updaterStatus) && updatedCount > 0) {
      hardRefreshInstalledPackages();
    }
    // Reset the state.
    setManageUpdatesMode(false);
    setUpdaterStatus([]);
    setUpdatedCount(0);
    setUpdatesQueue([]);
    setUpdatingAll(false);
  }

  // Callback when the update is finished.
  const updateQueueItemStatus = (item, error) => {
    setUpdaterStatus(prev => {
      const newStatus = PackageHelper.setUpdaterItemStatus(prev, item, error);
      setUpdatedCount(newStatus.filter(p => p.update_status === PackageHelper.STATUS_UPDATED).length);
      return newStatus;
    });
    setUpdatesQueue(prev => prev.slice(1));
  }

  const updateQueueItem = (item) => {
    item = item || ObjectHelper.first(updatesQueue);
    if (item) {
      // Update status to updating
      window.logHelper.info(`Updating "${item.folder_name}" ${item.type}...`, item);
      setUpdaterStatus(prev => prev.map(p => {
        if (p.slug === item.slug) {
          p.update_status = PackageHelper.STATUS_UPDATING;
        }
        return p;
      }));
      // Run the updater.
      runPackageUpdater(item, (error) => updateQueueItemStatus(item, error));
    } else {
      setUpdatingAll(false);
    }
  }

  const updateAll = () => {
    // If updating all, then check if there is an update in progress.
    if (updaterStatus.find(p => p.update_status === PackageHelper.STATUS_UPDATING)) {
      dispatch(setGlobalInfoMsg('Please wait until the current update finishes.'));
      return;
    }
    setUpdatedCount(0); // Reset in case a user stops and then starts again.
    setUpdatingAll(true);
    setUpdatesQueue(updaterStatus.filter(p => p.latest_version && p.status !== PackageHelper.STATUS_UPDATED));
    // Set all packages that are not updated or updating to queued.
    setUpdaterStatus(prev => prev.filter(p => p.latest_version).map(p => {
      if (p.latest_version && p.update_status !== PackageHelper.STATUS_UPDATED && p.update_status !== PackageHelper.STATUS_UPDATING) {
        p.update_status = PackageHelper.STATUS_QUEUED;
      }
      return p;
    }));
  }

  const stopUpdates = () => {
    setUpdatedCount(0);
    setUpdatesQueue([]);
    setUpdatingAll(false);
    setUpdaterStatus(prev => prev.map(p => {
      if (p.update_status === PackageHelper.STATUS_QUEUED) {
        p.update_status = null;
        p.update_error = null;
      }
      return p;
    }));
    window.logHelper.info(`Stopped updating ${type}s.`);
  }

  // --------------------------------------------------------
  // MODAL METHODS
  // --------------------------------------------------------

  const closeModal = () => {
    setModalSettings(false);
    setModalLoading(false);
  }

  const openModal = (title, callback, text, btnText) => {
    setModalSettings({
      title,
      onConfirm: () => {
        setModalLoading(true);
        callback();
      },
      onClose: closeModal,
      btnText,
      header: text,
      confirmColor: 'info',
    });
  }

  const openForceActivateThemeModal = (item) => {
    openModal(
      'Force Activate Theme',
      () => activateTheme(item, true),
      <Fragment>Are you sure you want to enforce the activation of{' '}<strong>{item.display_name}</strong> theme?</Fragment>,
      'Yes'
    );
  }

  const openForceDeactivatePluginModal = (item) => {
    openModal(
      `Force Plugin Deactivation`,
      () => deactivatePlugin(item, true, false),
      <Fragment>Are you sure you want to enforce the deactivation of{' '}<strong>{item.display_name}</strong> plugin?</Fragment>,
      'Yes'
    );
  }

  const openUpdatePackageModal = (item) => {
    if (updateLoading) {
      dispatch(setGlobalWarningMsg('Please wait until the current update finishes.'));
      return;
    }
    openModal(
      `Update ${typeCapitalized}`,
      () => runPackageUpdater(item),
      <Fragment>Are you sure you want to update the {' '}<strong>{item.display_name}</strong> {type} from version <strong>{item.version}</strong> to <strong>{item.latest_version}</strong>?</Fragment>,
      'Yes'
    );
  }

  // --------------------------------------------------------
  // TABLE METHODS
  // --------------------------------------------------------

  const handleAutoUpdate = (item, checked) => {
    if (loadingAutoUpdate) {
      dispatch(setGlobalPleaseWaitMsg({ model: `${type} auto update`, action: checked ? 'enabled' : 'disabled' }));
      return;
    }

    if (['global', 'partner'].includes(item.scope)) {
      dispatch(setGlobalInfoMsg(`You cannot enable auto update for a global ${type}s from here.`));
      return;
    }

    setAutoUpdate(item, checked);
  }

  const handleActivation = (item, checked, el) => {
    if (loadingAutoUpdate) {
      dispatch(setGlobalPleaseWaitMsg({ model: `${type} auto update`, action: 'set' }));
      return;
    }
    if (loadingActive) {
      dispatch(setGlobalPleaseWaitMsg({ model: type, action: checked ? 'activated' : 'deactivated' }));
      return;
    }

    if (isPlugin) {
      checked ? activatePlugin(item, el) : deactivatePlugin(item, false, el);
    } else {
      activateTheme(item, false);
    }
  };

  const actions = [
    {
      value: 'Update',
      doHide: item => PackageHelper.isManaged(item) || // Updating managed is done globally.
                      PackageHelper.isGit(item) ||
                      !UserHelper.hasPermissions(`website:update:${website.slug}`) ||
                      !item.latest_version_url,
      onClick: (item) => openUpdatePackageModal(item),
    },
    {
      value: 'File Manager',
      doHide: item => !PackageHelper.isInstalled(item) || // File manager for managed is not allowed.
                      PackageHelper.isGit(item) ||
                      !UserHelper.hasPermissions(`website:update:${website.slug}`),
      onClick: async item => {
        history.push({
          pathname: `${history.location.pathname}/${item.folder_name}/file-manager`,
        });
      },
    },
    {
      value: 'Git History',
      doHide: item => !PackageHelper.isGit(item) ||
                      !UserHelper.hasPermissions(`website:update:${website.slug}`),
      onClick: goToGitEvents,
    },
    {
      value: 'Copy Webhook URL',
      doHide: item => !PackageHelper.isGit(item) ||
                      !UserHelper.hasPermissions(`website:update:${website.slug}`),
      onClick: requestGitWebhookURL,
    },
    {
      value: 'Upload New',
      doHide: item => PackageHelper.isManaged(item) || // Uploading managed is done globally.
                      PackageHelper.isGit(item) || // Git plugins can't be uploaded, must be deleted and recreated.
                      !UserHelper.hasPermissions(`website:update:${website.slug}`),
      onClick: openPackageUploader,
    },
    {
      value: 'Force Activation',
      doHide: item => item.is_active ||
                      item.type !== 'theme',
      onClick: openForceActivateThemeModal,
    },
    {
      value: 'Force Deactivation',
      doHide: item => !item.is_active ||
                      item.type !== 'plugin' ||
                      (item.must_install && !UserHelper.isAdminOrAgent()), // Only admins can deactivate must-install plugins.
      onClick: openForceDeactivatePluginModal,
    },
    {
      value: 'Pull Changes',
      doHide: item => !PackageHelper.isGit(item) ||
                      !UserHelper.hasPermissions(`website:update:${website.slug}`),
      onClick: reinstallManagedPackage,
    },
    {
      value: 'Reinstall',
      doHide: item => PackageHelper.isUnmanaged(item) ||
                      PackageHelper.isGit(item) ||
                      !UserHelper.hasPermissions(`website:update:${website.slug}`),
      onClick: item => DialogHelper
        .confirmAction(confirm, `Reinstall`, item.display_name, typeCapitalized, false, true)
        .then(() => reinstallManagedPackage(item)),
    },
    {
      value: 'Delete',
      doHide: (item) => PackageHelper.isGlobalOrPartner(item) || // Can only be deactivated, not deleted.
                    !UserHelper.hasPermissions(`website:update:${website.slug}`),
      onClick: item => DialogHelper
        .confirmDelete(confirm, item && item.display_name ? item.display_name : '', item && item.type ? item.type : 'package')
        .then(() => deletePackage(item))
    },
  ];

  const headers = !manageUpdatesMode ? [
    PackageHelper.renderScopeHeader(),
    PackageHelper.renderDisplayNameHeader(typeCapitalized),
    PackageHelper.renderVersionHeader(),
    isPlugin ? PackageHelper.renderMustInstallHeader(false, website) : false,
    PackageHelper.renderAutoUpdateHeader(website, autoUpdaterConfig, handleAutoUpdate, loadingAutoUpdate, partnerPackages),
    PackageHelper.renderIsActiveHeader(handleActivation, loadingActive),
    JsxHelper.createTableActionsHeader(actions, isPlugin ? '29%' : '39%'),
  ].filter(Boolean) : PackageHelper.manageUpdatesHeaders(typeCapitalized, setUpdaterStatus, updateQueueItem, updatingAll);

  // --------------------------------------------------------
  // RENDER VIEW
  // --------------------------------------------------------

  const getTitle = () => {
    if (upload) {
      return `Upload ${typeCapitalized}`
    } else if (manageUpdatesMode) {
      return `Manage ${typeCapitalized} Updates`;
    }
    return `${typeCapitalized}s`;
  }

  return (
    <Container className='margin-0'>
      <TitleBar className='titlebar'>
        <TitleBar.Title>{getTitle()}</TitleBar.Title>
        <TitleBar.Actions>
          {!upload ? (<Fragment>
              {manageUpdatesMode && <Fragment>
                {!updatingAll && (!isEmpty(updaterStatus.filter(p => p.latest_version_url))) && <WPSButton
                  className='action--btn'
                  disabled={updateLoading}
                  onClick={updateAll}>
                  Update All
                </WPSButton>}
                {!updatingAll && JsxHelper.createCloseButton({
                  loading: tableLoading,
                  onClick: disableManageUpdatesMode
                })}
                {updatingAll && <div>
                  <div style={{ padding: '8px', display: 'inline-block' }}>
                    Updated {updatedCount} / {updatesQueue.length + updatedCount} {type}s
                  </div>
                  <WPSButton
                    style={{ float: 'right' }}
                    className='danger--btn'
                    onClick={stopUpdates}>
                    Stop
                  </WPSButton>
                </div>}
              </Fragment>}
              {!manageUpdatesMode && <Fragment>
                {updateAvailableUpdates() && <WPSButton
                  className='info--btn'
                  disabled={tableLoading || updateLoading}
                  onClick={enableManageUpdatesMode}>
                  Manage Updates
                </WPSButton>}
                {JsxHelper.createRefreshButton({
                  loading: tableLoading,
                  onClick: () => hardRefreshInstalledPackages(),
                })}
                <WPSButton
                  className='upload--btn'
                  onClick={e => {
                    setUpload(!upload);
                    setCurrentPackage(null);
                    setCustomBackBtn(true);
                  }}>
                  Add {typeCapitalized}
                </WPSButton>
                <WPSButton
                  style={{textTransform: 'inherit'}}
                  className='primary--btn'
                  onClick={() => goToConnectGitPackages()}>
                  Connect via Git
                </WPSButton>
              </Fragment>}
            </Fragment>
          ) : null}
        </TitleBar.Actions>
      </TitleBar>
      {!upload && !manageUpdatesMode && <Fragment>
        {PackageHelper.pageHeader(type, goToGlobalPackages)}
      </Fragment>}
      <Content>
        {upload && (
          <SavePackage
            type={type}
            website={website.slug}
            currentPackage={currentPackage}
            onClickCancel={() => setUpload(false)}
            postUploadSuccess={() => {
              refreshAfterPackageChange('upload');
              setUpload(false);
            }}
          />
        )}
        {!upload && !manageUpdatesMode && (
          <WPSDataTable dataKey={'folder_name'} customClass='packages-table' columns={headers} body={websitePackages} loading={tableLoading} rowsPerPage={100} />
        )}
        {!upload && manageUpdatesMode && (
          <WPSDataTable customClass='package-history-table' columns={headers} body={updaterStatus} loading={tableLoading} noSearchOnTable={true} />
        )}
        {modalSettings && DialogHelper.inputs({
          ...modalSettings,
          loading: modalLoading,
        })}
      </Content>
    </Container>
  );
};

export default Packages;
