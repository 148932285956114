import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CustomRoutes from 'components/wpstaq/customRoutes';
// Styles
import { Layout } from '../styles/globalStyles';
import SideBar from '../components/layout/sidebar';
import UserBar from 'components/layout/userBar';

// Pages
import DashboardIndex from './dashboard';

import WebsiteIndex from './website';
import WebsiteProfile from './website/profile';

import WebsiteCreateOptions from './website/create';
import WebsiteCreate from '../components/website/CreateWebsiteOptions/WebsiteCreate';
import WebsiteClone from '../components/website/CreateWebsiteOptions/WebsiteClone';
import WebsiteStaging from '../components/website/CreateWebsiteOptions/WebsiteStaging';
import WebsiteMigrate from '../components/website/CreateWebsiteOptions/WebsiteMigrate';
import WebsiteGitDeploy from '../components/website/CreateWebsiteOptions/WebsiteGitDeploy';

import ThemeIndex from './theme';
import ThemeCreate from './theme/create';
import ThemeUpdate from './theme/update';
import ThemeGitConnect from './theme/git-connect';
import ThemeGitEvents from './theme/git-events';

import PluginIndex from './plugin';
import PluginCreate from './plugin/create';
import PluginUpdate from './plugin/update';
import PluginGitConnect from './plugin/git-connect';
import PluginGitEvents from './plugin/git-events';

import WordPressIndex from './wordpress';
import WordPressCreate from './wordpress/create';

import NotificationIndex from './notification';

import UserIndex from './user';
import UserCreate from './user/create';
import UserUpdate from './user/update';
import UserActivity from './user/activity';
import UserChildren from './user/children';

import CustomerIndex from './customer';
import Account from './customer/account';
import Plan from './customer/plan/index';
import Stripe from './customer/stripe';
import Create from './customer/create';
import Update from './customer/update';
import CustomerPlanCreate from './customer/plan/create';
import Subscription from './customer/subscription';
import PlanUpdate from './customer/plan/update';
import Billing from './customer/billing';

import PaymentMethods from './customer/paymentmethods';
import SubscriptionUsage from 'pages/subscription/subscriptionUsage';
import Invoice from 'pages/billing/invoice';
import PaymentIntents from 'pages/billing/paymentIntents';

import RegionIndex from './region';
import RegionCreate from './region/create';
import RegionUpdate from './region/update';

import DatabaseIndex from './database';

import ServerIndex from './server';
import ServerCreate from './server/create';
import ServerInstance from './server/Instance';
import ServerVolumes from './server/volume';
import ServerPhpConfig from './server/phpConfig';
import ServerOPcacheStats from './server/opcacheStats';
import ServerAccessStats from './server/accessStats';
import ServerRedisStats from './server/redisStats';

import ReportsIndex from './reports';
import BillingIndex from './billing';

import DNSZoneIndex from './dnsZone';
import DNSZoneRecord from './dnsZone/record';
import DNSZoneSetup from './dnsZone/setup';
import Settings from './settings';

import ProfileIndex from './profile';

import ToolIndex from './tool';

import EmailTemplates from './settings/common/emailtemplates';
import Legal from './settings/common/legal';
import CustomDeployment from './settings/common/CustomDeployment';
import Cloudflare from './settings/common/Cloudflare';
import SendGrid from './settings/common/SendGrid';
import GlobalSMTPMailer from './settings/common/SMTP/GlobalSMTPMailer';
import CustomSMTPServer from './settings/common/SMTP/CustomSMTPServer';

import WPDefines from 'components/website/advanced/wpDefines';
import NginxConfig from 'components/website/advanced/nginxConfig';
import RemoteBackupStorage from 'components/website/advanced/remoteBackupStorage';
import AutoUpdates from 'components/website/advanced/autoUpdates';

import SearchWebsitePackages from 'components/tools/searchWebsitePackages';
import BulkReset from 'components/website/bulkReset';
import BulkPurge from 'components/website/bulkPurge';
import BulkUpdatePackages from 'components/website/bulkUpdatePackages';

const Index = () => {
  return (
    <Layout>
      <SideBar />
      <UserBar />
      <Switch>
        <Route exact path='/'>
          <DashboardIndex />
        </Route>
        <Route exact path='/profile'>
          <ProfileIndex />
        </Route>
        <Route exact path='/users'>
          <UserIndex />
        </Route>
        <Route exact path='/users/create'>
          <UserCreate />
        </Route>
        <Route exact path='/users/:slug/update'>
          <UserUpdate />
        </Route>
        <Route exact path='/users/:slug/activity'>
          <UserActivity />
        </Route>
        <Route path='/users/:slug/children'>
          <UserChildren />
        </Route>
        <Route exact path='/regions'>
          <RegionIndex />
        </Route>
        <Route exact path='/regions/create'>
          <RegionCreate />
        </Route>
        <Route path='/regions/:slug'>
          <RegionUpdate />
        </Route>
        <Route exact path='/databases'>
          <DatabaseIndex />
        </Route>
        <Route exact path='/servers'>
          <ServerIndex />
        </Route>
        <Route exact path='/servers/create'>
          <ServerCreate />
        </Route>
        <Route path='/servers/:slug/php-config'>
          <ServerPhpConfig />
        </Route>
        <Route path='/servers/:slug/opcache-stats'>
          <ServerOPcacheStats />
        </Route>
        <Route path='/servers/:slug/redis-stats'>
          <ServerRedisStats />
        </Route>
        <Route path='/servers/:slug/access-stats'>
          <ServerAccessStats />
        </Route>
        <Route path='/servers/:slug/instances/:instanceSlug/volumes'>
          <ServerVolumes />
        </Route>
        <Route path='/servers/:slug/instances'>
          <ServerInstance />
        </Route>
        <Route exact path='/websites'>
          <WebsiteIndex />
        </Route>
        <Route exact path='/website'>
          <WebsiteCreateOptions />
        </Route>
        <Route exact path='/website/create'>
          <WebsiteCreate />
        </Route>
        <Route exact path='/website/clone'>
          <WebsiteClone />
        </Route>
        <Route exact path='/website/staging'>
          <WebsiteStaging />
        </Route>
        <Route exact path='/website/migrate'>
          <WebsiteMigrate />
        </Route>
        <Route exact path='/website/git-deploy'>
          <WebsiteGitDeploy />
        </Route>
        <Route path='/websites/:slug'>
          <WebsiteProfile />
        </Route>
        <Route exact path='/themes'>
          <ThemeIndex />
        </Route>
        <Route exact path='/themes/create'>
          <ThemeCreate />
        </Route>
        <Route exact path='/themes/update'>
          <ThemeUpdate />
        </Route>
        <Route exact path='/plugins'>
          <PluginIndex />
        </Route>
        <Route exact path='/plugins/create'>
          <PluginCreate />
        </Route>
        <Route exact path='/plugins/git-connect'>
          <PluginGitConnect />
        </Route>
        <Route exact path='/plugins/git-events'>
          <PluginGitEvents />
        </Route>
        <Route exact path='/themes/git-connect'>
          <ThemeGitConnect />
        </Route>
        <Route exact path='/themes/git-events'>
          <ThemeGitEvents />
        </Route>
        <Route exact path='/plugins/update'>
          <PluginUpdate />
        </Route>
        <Route exact path='/dns-zones'>
          <DNSZoneIndex />
        </Route>
        <Route exact path='/dns-zones/setup'>
          <DNSZoneSetup />
        </Route>
        <Route path='/dns-zones/:slug/records'>
          <DNSZoneRecord />
        </Route>
        <Route exact path='/wordpress'>
          <WordPressIndex />
        </Route>
        <Route exact path='/wordpress/create'>
          <WordPressCreate />
        </Route>
        <Route exact path='/clients'>
          <CustomerIndex />
        </Route>
        <Route exact path={'/clients/accounts'}>
          <Account />
        </Route>
        <Route exact path={'/clients/create'}>
          <Create />
        </Route>
        <Route exact path={'/clients/update'}>
          <Update />
        </Route>
        <Route exact path={'/clients/plans'}>
          <Plan />
        </Route>
        <Route exact path={'/clients/subscriptions'}>
          <Subscription />
        </Route>
        <Route exact path={'/clients/:subscriptionName/usage'}>
          <SubscriptionUsage type='customer' />
        </Route>
        <Route exact path={'/clients/:planSlug/subscriptions'}>
          <Subscription />
        </Route>
        <Route exact path={'/clients/invoices'}>
          <Invoice customers={true} />
        </Route>
        <Route exact path={'/clients/plan/create'}>
          <CustomerPlanCreate />
        </Route>
        <Route exact path={'/clients/:planSlug/update'}>
          <PlanUpdate />
        </Route>
        <Route exact path={'/clients/stripe'}>
          <Stripe />
        </Route>
        <Route exact path={'/clients/billing'}>
          <Billing />
        </Route>
        <Route exact path={'/clients/:customerSlug/payments'}>
          <PaymentMethods />
        </Route>
        <Route exact path={'/clients/payment-intents'}>
          <PaymentIntents customer={true} />
        </Route>
        <Route exact path={'/clients/email-templates'}>
          <EmailTemplates />
        </Route>
        <Route exact path={'/clients/custom-smtp'}>
          <CustomSMTPServer />
        </Route>
        <Route exact path={'/clients/legal'}>
          <Legal />
        </Route>
        <Route exact path='/reports'>
          <ReportsIndex />
        </Route>
        <Route exact path='/billing'>
          <BillingIndex />
        </Route>
        <Route exact path='/settings/nginx-config'>
          <NginxConfig />
        </Route>
        <Route exact path='/settings/wp-config'>
          <WPDefines />
        </Route>
        <Route exact path='/settings/smtp-mailer'>
          <GlobalSMTPMailer />
        </Route>
        <Route exact path='/settings/remote-backup-storage'>
          <RemoteBackupStorage />
        </Route>
        <Route exact path='/settings/auto-updater'>
          <AutoUpdates />
        </Route>
        <Route exact path='/settings/deployment'>
          <CustomDeployment />
        </Route>
        <Route exact path='/settings/cloudflare'>
          <Cloudflare />
        </Route>
        <Route exact path='/settings/sendgrid'>
          <SendGrid />
        </Route>
        <Route path='/settings'>
          <Settings />
        </Route>
        <Route exact path='/tools'>
          <ToolIndex /> 
        </Route>
        <Route exact path='/tools/bulk-reset'>
          <BulkReset />
        </Route>
        <Route exact path='/tools/bulk-update'>
          <BulkUpdatePackages />
        </Route>
        <Route exact path='/tools/bulk-purge'>
          <BulkPurge />
        </Route>
        <Route exact path='/tools/search-website-packages'>
          <SearchWebsitePackages/>
        </Route>
        <Route exact path='/notifications'>
          <NotificationIndex />
        </Route>
        <CustomRoutes />
      </Switch>
    </Layout>
  );
};

export default Index;
