import React from 'react';
import JsxHelper from 'helpers/jsx';
import DialogHelper from 'helpers/dialog';
import UserHelper from 'helpers/user';
import WebsiteHelper from 'helpers/website';
import ArrayHelper from 'helpers/array';
import { isEmpty, isEmptyOrNull } from 'helpers';
import australiaFlag from 'assets/australia-flag.png';
import unitedKingdom from 'assets/united-kingdom.png';
import usaAmerica from 'assets/united-states-of-america.png';
import { createPartnerWebsite, clonePartnerWebsite, createPartnerWebsiteStaging, deployPartnerGitWebsite } from 'store/website/websiteActions';
import { fetchSubscriptions } from 'store/billing/billingActions';
import { createNewCustomer } from 'store/customer/customerActions';
import { setGlobalSuccessMsg, setGlobalErrorMsg } from 'store/global/globalActions';
import { WPSForm, WPSLabel, WPSInput, ErrorMsg } from 'styles/layout/forms';
import { WPSButton } from 'styles/layout/buttons';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import PricingPlans from 'components/billing/pricingPlans';
import env from 'config/env';
import FormHelper from 'helpers/form';
import WebsiteService from 'services/website';

/* API */

const onWebsiteIdBlur = (website, setLoading, loading, setError, setExists, onNotExists, modalDialog) => {
  if (loading) {
    window.logHelper.warning('Check if website exists API is loading.');
    return;
  }
  setError(false);
  setExists(false);
  const slugTest = WebsiteHelper.isValidSlug(website.website_slug);
  if (slugTest.valid) {
    setError(false);
    setLoading(true);
    WebsiteService.checkIfExists({ website_slug: website.website_slug })
      .then(res => {
        if (res.result) {
          setExists(true);
          modalDialog && DialogHelper.warning(modalDialog, 'The website ID already exists.');
        } else {
          onNotExists && onNotExists(website);
        }
      }).catch((err) => {
        const error = err.data && err.data.error ? err.data.error : 'The website ID format is invalid.';
        modalDialog && DialogHelper.error(modalDialog, error);
        setError(error);
      })
      .finally(() => setLoading(false));
  } else {
    setError(slugTest.error);
    setLoading(false);
    modalDialog && DialogHelper.error(modalDialog, slugTest.error);
  }
};

/* Stepper */

const initStep = (stepperRef, disabledStepsRef, stepNum, stepsCount, setCurrentStep) => {
  for (var i = 1; i <= stepsCount; i++) {
    const stepIndex = i - 1;
    let step = stepperRef.current.children[0].children[0].children[stepIndex].children[0];
    step.classList.remove('complete-color');
    step.classList.remove('warning-color');
    step.classList.remove('danger-color');
    step.classList.remove('active-color');
    if (stepNum === i) {
      // Color the clicked step with active-color
      step.classList.add('active-color');
    } else if (!disabledStepsRef.current.includes(stepIndex)) {
      // Color the clicked step with the state color
      step.classList.add('complete-color');
    }
  }
  setCurrentStep(stepNum);
}

/* Render */

const renderRegionsAndZones = (onSelect) => 
  <WPSForm.Fieldset style={{ marginTop: '0px' }}>
    <legend>Regions and Zones</legend>
    <WPSForm.RowItem className='website-id-container'>
    <div className='display-flex'>
      {JsxHelper.createLabel('Choose an AWS Region', 'region_slug', true)}
      {JsxHelper.createBasicTooltip(WebsiteHelper.tooltips.region_id)}
    </div>
    <div className='flags-container'>
      <div className='flag-item'>
        <img src={australiaFlag} alt='australia' />
        <label htmlFor='ap-southeast-2'>Sydney (AU)</label>
        <input
          name='region_slug'
          id='ap-southeast-2'
          type='radio'
          defaultChecked={true}
          onChange={onSelect}
        />
      </div>
      <div className='flag-item' data-tip='Coming soon!' data-for='use_america'>
        <img src={usaAmerica} alt='USA' />
        <label className='disabled' htmlFor='use_america'>North Virginia (US East)</label>
        <input
          name='region_slug'
          id='use_america'
          type='radio'
          disabled={true}
          onChange={onSelect}
        />
        {JsxHelper.createTooltip('use_america')}
      </div>
      <div className='flag-item' data-tip='Coming soon!' data-for='united_kingdom'>
        <img src={unitedKingdom} alt='UK' />
        <label className='disabled' htmlFor='united_kingdom'>London (UK)</label>
        <input
          name='region_slug'
          id='united_kingdom'
          type='radio'
          disabled={true}
          onChange={onSelect}
        />
        {JsxHelper.createTooltip('united_kingdom')}
      </div>
    </div>
    </WPSForm.RowItem>
  </WPSForm.Fieldset>

const renderEnterAppID = (website, websiteExists, websiteExistsError, __onChange, id) => {
  const inputId = id || 'website_slug';
  return <WPSForm.Fieldset>
    <legend>{env.getBrandShortName()} Account</legend>
    <WPSForm.RowItem className='website-id-container custom-form-row-item'>
      <div className='display-flex'>
        <WPSLabel htmlFor={inputId} required>
          Enter a {env.getBrandShortName()} ID for your website
        </WPSLabel>
        {JsxHelper.createBasicTooltip(WebsiteHelper.tooltips.website_id)}
      </div>
      <WPSInput
        type='text'
        name={inputId}
        id={inputId}
        style={{ maxHeight: '36px' }}
        value={website[inputId] || ''}
        onChange={__onChange}
      />
      {websiteExists && (
        <ErrorMsg className='margin-top-5'>{env.getBrandShortName()} ID already exists</ErrorMsg>
      )}
      {websiteExistsError && (
        <ErrorMsg className='margin-top-5'>{websiteExistsError}</ErrorMsg>
      )}
    </WPSForm.RowItem>
  </WPSForm.Fieldset>
}

const renderSelectPartnerForAdmins = (website, partners, errors, register, __onChange) => {
  return UserHelper.isAdminOrAgent() ?
  <WPSForm.Fieldset>
  <legend>Partner Account</legend>
    <WPSForm.RowItem className='custom-form-row-item'>
      <WPSLabel className='margin-bottom-12' required>
        Select a partner account
      </WPSLabel>
      <WPSSelect
        name='partner_slug'
        value={website.partner_slug}
        noneOption={true}
        options={ArrayHelper.buildSelectOptions(partners, 'display_name', 'slug')}
        isSearchable={true}
        onChange={__onChange}
        required={true}
        refId='partner_slug'
        register={register}
      />
      {errors.partner_slug && (
        <ErrorMsg className='margin-top-5'>{errors.partner_slug.message}</ErrorMsg>
      )}
    </WPSForm.RowItem>
  </WPSForm.Fieldset> : null
}

const renderPricingPlansForPartner = (website, partnerPlans, __onChange) => {
  return !UserHelper.isAdminOrAgent() ? (
  <WPSForm.Fieldset>
    <legend>{env.getBrandShortName()} Pricing Plans</legend>
    <WPSForm.RowItem className='website-id-container'>
      <div className='display-flex'>
        <WPSLabel htmlFor='website_slug' required>
          Subscribe to a {env.getBrandShortName()} plan
        </WPSLabel>
        {JsxHelper.createBasicTooltip(WebsiteHelper.tooltips.website_plan_id)}
      </div>
      {partnerPlans && (
        <PricingPlans
          type='website'
          name='partner_plan_slug'
          selectedSlug={website.partner_plan_slug}
          customCard={true}
          plans={partnerPlans}
          handleOnClick={__onChange}
        />
      )}
    </WPSForm.RowItem>
  </WPSForm.Fieldset> ) : null
}

const renderPricingPlansForAdmins = (website, partnerPlans, __onChange) => {
  return UserHelper.isAdminOrAgent() && !isEmptyOrNull(website.partner_slug) ? (
  <WPSForm.Fieldset>
    <legend>Partner Plans</legend>
    {partnerPlans && (
      <PricingPlans
        type='website'
        name='partner_plan_slug'
        selectedSlug={website.partner_plan_slug}
        customCard={true}
        plans={partnerPlans}
        handleOnClick={__onChange}
      />
    )}
  </WPSForm.Fieldset>) : null
}

const renderServersForAdmins = (website, servers, selectedPlan, register, __onChange, allWebsites) => {
  let serversList = servers.map(s => {
    s.sites_count = allWebsites.filter(w => w.server_slug === s.slug).length;
    const label = `${s.slug} (${s.instance_type} - ${s.sites_count} / ${s.max_websites} websites)`;
    const sites_available = s.max_websites - s.sites_count;
    return { ...s, label, sites_available };
  }); // Set label and available sites
  serversList = serversList.sort((a, b) => b.sites_available - a.sites_available); // Sort by available sites
  serversList = serversList.filter(s => !s.is_staging); // Remove staging servers
  const selectOptions = ArrayHelper.buildSelectOptions(serversList, 'label', 'slug');
  return UserHelper.isAdminOrAgent() && selectedPlan && selectedPlan.slug.includes('pay_as_you_go') ? (
  <WPSForm.Fieldset>
    <legend>{env.getBrandShortName()} Servers</legend>
    <WPSForm.RowItem className='custom-form-row-item'>
      {JsxHelper.createSelectInput({
        label: `Select a "${selectedPlan.name}" server (optional)`,
        name: 'cluster_slug',
        value: website.cluster_slug,
        options: selectOptions,
        isSearchable: true,
        onChange: __onChange,
        register,
        sortOff: true,
        refId: 'cluster_slug',
      })}
    </WPSForm.RowItem>
  </WPSForm.Fieldset>) : null
}

const renderAgencyPlans = (website, customerPlans, __onChange) =>
  <WPSForm.Fieldset>
    <legend>Agency Plans</legend>
    {customerPlans && (<PricingPlans
      type='website'
      name='customer_plan_slug'
      selectedSlug={website.customer_plan_slug}
      customCard={true}
      plans={customerPlans}
      handleOnClick={__onChange}
    />)}
  </WPSForm.Fieldset>

const renderAssignClient = (website, customers, customersTrigger, __onChange, __setModal) =>
  <WPSForm.Fieldset style={{ marginTop: '0px' }}>
    <legend>Agency Clients</legend>
    <WPSForm.RowItem className='custom-form-row-item'>
      <WPSLabel className='margin-bottom-12'>
        Assign a client to this website
      </WPSLabel>
      <WPSSelect
        name='customer_slug'
        value={website.customer_slug}
        noneOption={true}
        options={ArrayHelper.buildSelectOptions(customers, 'display_name', 'slug')}
        onChange={__onChange}
        isSearchable={true}
        key={customersTrigger || null}
      />
      {!website.customer_slug && JsxHelper.createButton({
        label: 'Create New Client',
        style: { marginTop: '16px', width: '160px' },
        onClick: () => __setModal(true),
      })}
    </WPSForm.RowItem>
  </WPSForm.Fieldset>

const renderSelectInputRowItem = (label, name, website, options, required, __onChange, isMulti) => {
  return <WPSForm.RowItem className='custom-form-row-item'>
    {JsxHelper.createSelectInput({
      label: label,
      name: name,
      value: isMulti ? (website[name] || []).map(o => o.value) : website[name],
      options: options,
      onChange: __onChange,
      multiSelect: isMulti,
      required: required,
      isSearchable: true,
    })}
  </WPSForm.RowItem>
}

const renderTextInputRowItem = (label, name, website, errors, register, required, __onChange) => {
  const __refCallback = {};
  if (required) {
    __refCallback.required = FormHelper.messages.required;
  }
  return <WPSForm.RowItem className='custom-form-row-item'>
  <WPSLabel required={required}>
    {label}
    {errors[name] && <ErrorMsg>{errors[name].message}</ErrorMsg>}
  </WPSLabel>
  <WPSInput
    type='text'
    id={name}
    name={name}
    value={website[name]}
    onChange={__onChange}
    ref={register(__refCallback)}
  />
  </WPSForm.RowItem>
}

const renderLightButton = (label, __onClick, loading, disabled) => <WPSButton
  className='next-light--btn'
  style={{ marginTop: '16px' }}
  loading={loading || false}
  disabled={disabled || false}
  onClick={__onClick}>
  {label}
  </WPSButton>

/* Actions */

const fetchPartnerSubscriptionsForAdmins = (partnersSubscriptions, __dispatch) => {
  if (UserHelper.isAdminOrAgent()) {
    let fetchted = false; // Prevent infinite loop
    const data = {
      subscriber_type: 'partner',
      active_only: true,
    };
    if (isEmpty(partnersSubscriptions) && !fetchted) {
      __dispatch(fetchSubscriptions(data)).then(() => {
        fetchted = true;
      });
    }
  }
};

const onPartnerPlanSelection = (
    planSlug,
    websiteActiveSubscriptions,
    partnerPlans,
    __setSelectedPlan,
    __setWebsiteData
) => {
  // Check if any plan exists
  if (isEmptyOrNull(partnerPlans)) {
    return;
  }
  // Init the selected plan
  if (!planSlug) {
    const defaultPlan = partnerPlans.find(p => p.is_default);
    planSlug = defaultPlan.slug || partnerPlans[0].slug;
  }
  const plan = partnerPlans.find(p => p.slug === planSlug);
  if (!plan) {
    window.logHelper.warning('Partner plan not found', planSlug);
    return;
  }
  // Find subscription associated with the plan
  let activeSubscription = null;
  if (!isEmptyOrNull(websiteActiveSubscriptions)) {
    activeSubscription = websiteActiveSubscriptions.find(s => s.plan_slug === planSlug);
  }

  // Set active subscription if plan doesn't allow multi-subscriptions such
  // as upgrade-only plans and notify user
  let subscriptionGuid = null;
  if (!plan.allow_multi_subs) {
    if (activeSubscription) {
      subscriptionGuid = activeSubscription.guid;
    }
  }
  // Set input data
  __setWebsiteData(prev => ({
    ...prev,
    partner_plan_slug: plan.slug,
    partner_subscription_guid: subscriptionGuid,
  }));
  __setSelectedPlan && __setSelectedPlan(plan);
};

const createNewClientModal = (
  loading,
  customerLoading,
  customerDetails,
  errors,
  register,
  __setModal,
  __onConfirm,
  __setCustomerDetails
) => {
  const __onChangeCustomer = e => {
    const { name, value, checked } = e.target;
    if (name === 'request_profile_setup') {
      __setCustomerDetails(prev => ({ ...prev, request_profile_setup: checked }));
    } else {
      __setCustomerDetails(prev => ({ ...prev, [name]: value }));
    }
  };

  return DialogHelper.inputs({
    title: 'Create New Client',
    onClose: () => __setModal(false),
    onConfirm: __onConfirm,
    loading: customerLoading,
    confirmBtn: 'Create',
    register,
    inputs: [{
      label: 'Display name',
      required: true,
      name: 'display_name',
      value: customerDetails.display_name,
      onChange: __onChangeCustomer,
      errors,
    }, {
      label: 'Email',
      name: 'email',
      required: true,
      value: customerDetails.email,
      onChange: __onChangeCustomer,
      errors,
    }, {
      label: 'Send profile setup request to client',
      name: 'request_profile_setup',
      type: 'checkbox',
      onChange: __onChangeCustomer,
      disabled: loading,
      checked: customerDetails.request_profile_setup,
      tooltip: WebsiteHelper.tooltips.request_customer_profile_setup,
      errors,
    }]
  })
};

const initWebsiteData = (
  partner,
  regionSelectOptions,
  customerPlans,
  partnerThemes,
  globalThemes,
  globalCores
) => {
  // Get default customer plan
  const defaultCustomerPlan = isEmptyOrNull(customerPlans) 
    ? null
    : customerPlans.find(p => !p.assignee_slug && p.is_default);

  // Get default theme slug
  let defaultTheme = (partnerThemes || []).find(t => t.must_install);
  if (isEmptyOrNull(defaultTheme)) {
    defaultTheme = (globalThemes || []).find(t => t.must_install);
  }

  return {
    region_slug: !isEmptyOrNull(regionSelectOptions) ? regionSelectOptions[0].slug : '',
    partner_plan_slug: '',
    partner_subscription_guid: '',
    website_slug: '',
    theme_slug: defaultTheme ? defaultTheme.slug : '',
    wp_admin_user: WebsiteHelper.getCustomWPAdminUser(partner),
    wp_admin_user_email: partner ? partner.email : '',
    wp_version: !isEmptyOrNull(globalCores) ? globalCores[0].version : '',
    php_version: WebsiteHelper.phpVersions[0].value,
    site_title: '',
    plugin_slugs: [],
    migration_config: '',
    migration_data_url: '',
    customer_slug: '',
    partner_slug: '',
    customer_plan_slug: defaultCustomerPlan ? defaultCustomerPlan.slug : '',
    cluster_slug: null
  }
};

const initFormData = (website, action) => {
  let data = {
    region_slug: website.region_slug,
    partner_plan_slug: website.partner_plan_slug,
    partner_subscription_guid: website.partner_subscription_guid,
    website_slug: website.website_slug,
    wp_admin_user: website.wp_admin_user,
    wp_admin_user_email: website.wp_admin_user_email,
    wp_version: website.wp_version,
    site_title: website.site_title,
    theme_slug: website.theme_slug,
    plugin_slugs: !isEmpty(website.plugin_slugs) ? website.plugin_slugs.map(p => p.value) : [],
    customer_slug: website.customer_slug,
    partner_slug: website.partner_slug,
    customer_plan_slug: website.customer_plan_slug,
    php_version: website.php_version,
  };

  // If admin/agent user, remove customer data and allow selection of partner/cluster.
  if (UserHelper.isAdminOrAgent()) {
    delete data.customer_slug;
    delete data.customer_plan_slug;
    data.cluster_slug = website.cluster_slug
  } else {
    delete data.partner_slug;
  }

  // Prepare migration data.
  if (action === 'migrate') {
    data.migration_data_url = website.export_options.copy_link;
    delete data.php_version;
    delete data.site_title;
    delete data.plugin_slugs;
    delete data.theme_slug;
  } else {
    delete data.migration_data_url;
  }

  // Prepare clone data.
  if (action === 'clone') {
    data.src_website_slug = website.src_website_slug;
    data.dst_website_slug = website.website_slug;
    delete data.website_slug;
    delete data.php_version;
    delete data.site_title;
    delete data.plugin_slugs;
    delete data.theme_slug;
    delete data.wp_admin_user;
    delete data.wp_admin_user_email;
    delete data.wp_version;
  }

  // Prepare staging data.
  if (action === 'staging') {
    data = {
      website_slug: website.website_slug,
      staging_slug: website.staging_slug,
    }
  }

  // Prepare deploy git data.
  if (action === 'deploy-git') {
    data.repository_url = website.repository_url;
    data.repository_branch = website.repository_branch;
    data.ssh_keys_id = website.ssh_keys_id;
    data.webhook_api_key = website.webhook_api_key;
    data.auto_manage_wp_core = website.auto_manage_wp_core;
    data.auto_update_overwrite = website.auto_update_overwrite;
    delete data.plugin_slugs;
    delete data.theme_slug;
  }

  // If customer is not selected, remove customer plan.
  if (!data.customer_slug) {
    delete data.customer_plan_slug;
  }

  return data;
};

const EMPTY_CUSTOMER_DETAILS = {
  display_name: '',
  email: '',
  request_profile_setup: true,
};

const onCreate = (websiteExists, website, history, __dispatch, __setLoading, __action, actionName) => {
  if (!websiteExists) {
    const data = initFormData(website, actionName);
    __action = __action || createPartnerWebsite;
    __setLoading(true);
    __dispatch(__action(data))
      .then(res => {
        __dispatch(setGlobalSuccessMsg({ id: res.slug, model: 'website', action: 'created' }));
        WebsiteHelper.goTo(history, res.slug);
      })
      .finally(() => __setLoading(false));
  }
};

const onMigrate = (websiteExists, website, history, __dispatch, __setLoading, exportOptions) => {
  website.export_options = exportOptions;
  onCreate(websiteExists, website, history, __dispatch, __setLoading, null, 'migrate');
}

const onClone = (websiteExists, website, history, __dispatch, __setLoading) =>
  onCreate(websiteExists, website, history, __dispatch, __setLoading, clonePartnerWebsite, 'clone');

const onStaging = (website, history, __dispatch, __setLoading) =>
  onCreate(false, website, history, __dispatch, __setLoading, createPartnerWebsiteStaging, 'staging');

const onDeployGit = (website, history, __dispatch, __setLoading) =>
  onCreate(false, website, history, __dispatch, __setLoading, deployPartnerGitWebsite, 'deploy-git');

const createCustomer = async (
  customerDetails,
  __setModal,
  __setCustomersTrigger,
  __setLoadingCustomer,
  __triggerValidation,
  __setWebsiteData,
  __dispatch
) => {
  const result = await __triggerValidation(['display_name', 'email']);
  if (result) {
    __setLoadingCustomer(true);
    __dispatch(createNewCustomer(customerDetails))
      .then(res => {
        __dispatch(setGlobalSuccessMsg({ model: 'client', action: 'created' }));
        __setWebsiteData(prev => ({ ...prev, customer_slug: res.slug }));
        __setModal(false);
        __setCustomersTrigger(prev => prev + 1);
      })
      .catch(err => __dispatch(setGlobalErrorMsg(err)))
      .finally(() => __setLoadingCustomer(false));
  }
};


// Check whether to show a warning whenever the origin site has premium features
// to make sure the user is aware that the new site will not have those features.
const displayWarningIfOriginHasPremiumConfig = (originWebsiteSlug, allWebsites, modalDialog) => {
  const website = allWebsites.find(w => w.slug === originWebsiteSlug);
  if (!website) {
    DialogHelper.error(modalDialog, 'Origin website not found');
    return;
  }
  const warning = getCloneWarningIfOriginHasPremiumConfig(originWebsiteSlug, allWebsites, true);
  if (warning) {
    DialogHelper.warning(modalDialog, warning);
  }
}

const getCloneWarningIfOriginHasPremiumConfig = (originWebsiteSlug, allWebsites, asString) => {
  const website = allWebsites.find(w => w.slug === originWebsiteSlug);
  if (!website) {
    return false;
  }
  const phpConfig = WebsiteHelper.getPHPConfig(website);
  const premiumConfig = {
    memory_limit: { label: 'PHP Memory Limit', maxFree: 256 },
    pm_max_children: { label: 'PHP Max Workers', maxFree: 10 },
  }
  let premiumFound = false;
  Object.keys(premiumConfig).forEach(key => {
    if (phpConfig[key] && phpConfig[key] !== 'default') {
      if (parseInt(phpConfig[key]) > premiumConfig[key].maxFree) {
        premiumFound = premiumConfig[key].label;
      }
    }
  });
  if (premiumFound) {
    const before = 'The origin website has a premium';
    const after = 'configuration that will not be copied to the new website, but you can change it later.';
    return asString ? `${before} <b>${premiumFound}</b> ${after}` : <span>{before} <b>{premiumFound}</b> {after}</span>;
  }
  return false;
}

const WebsiteOptionsTrait = {
  renderRegionsAndZones,
  renderAgencyPlans,
  renderAssignClient,
  renderEnterAppID,
  renderSelectPartnerForAdmins,
  renderPricingPlansForPartner,
  renderPricingPlansForAdmins,
  renderServersForAdmins,
  renderLightButton,
  renderSelectInputRowItem,
  renderTextInputRowItem,
  EMPTY_CUSTOMER_DETAILS,
  fetchPartnerSubscriptionsForAdmins,
  onPartnerPlanSelection,
  createNewClientModal,
  initWebsiteData,
  createCustomer,
  displayWarningIfOriginHasPremiumConfig,
  getCloneWarningIfOriginHasPremiumConfig,
  onCreate,
  onClone,
  onStaging,
  onMigrate,
  onDeployGit,
  onWebsiteIdBlur,
  initStep,
}   
  
export default WebsiteOptionsTrait;