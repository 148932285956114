import React, { useState } from 'react';
import { TitleBar } from 'styles/layout/titlebar';
import { useDispatch, useSelector } from 'react-redux';
import useTitle from 'hooks/useTitle';
import { Content } from 'styles/globalStyles';
import { globalAutoUpdaterConfig } from 'store/setting/settingSelectors';
import { setGlobalSuccessMsg } from 'store/global/globalActions';
import { WPSForm } from 'styles/layout/forms';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import { updateAutoUpdaterNginxConfig } from 'store/setting/settingActions';
import JsxHelper from 'helpers/jsx';
import WebsiteHelper from 'helpers/website';
import UserHelper from 'helpers/user';
import { websitesSelector } from 'store/website/websiteSelectors';

const AutoUpdates = props => {
  useTitle('Settings - Auto Updater');

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const globalConfig = useSelector(globalAutoUpdaterConfig);
  const allWebsites = useSelector(websitesSelector);
  const AUTO_UPDATE_CONFIG = WebsiteHelper.getAutoUpdaterConfigOptions();
  const breadcrumbs = JsxHelper.createBreadcrumbs('Auto Plugin Updater', 'settings');

  // Options + Config Initializers

  const initialUserConfig = AUTO_UPDATE_CONFIG.reduce((acc, item) => {
    if (['include_websites', 'exclude_websites'].includes(item.name)) {
      acc[item.name] = (globalConfig[item.name] || item.default || []).filter(slug => allWebsites.find(website => website.slug === slug || slug === 'all'));
    } else {
      acc[item.name] = globalConfig[item.name] || item.default || null;
    }
    return acc;
  }, {});

  const maybeDisableConfigOptions = (configData, newDetails) => {
    const isGlobalUpdatesEnabled = newDetails.websites_update_status === 'enabled';
    const isAutoSchedule = newDetails.schedule_mode === 'auto';
    const isDailySchedule = newDetails.schedule_interval === 'daily';
    const newConfig = configData.map(item => {
      // Hide everything except immediate security updates when auto schedule is selected.
      item.hide = !isGlobalUpdatesEnabled && !['vulnerability_updates', 'websites_update_status'].includes(item.name);
      // If global auto updates are enabled, set rules.
      if (isGlobalUpdatesEnabled) {
        // Custom schedule is only available when auto updates are enabled.
        if (['schedule_interval', 'schedule_time', 'schedule_timezone'].includes(item.name)) {
          item.hide = isAutoSchedule; 
        }
        // Apply more rules.
        if (item.name === 'schedule_day') {
          item.hide = isDailySchedule || isAutoSchedule;
        } else if (item.name === 'exclude_websites') {
          item.hide = !newDetails.include_websites.includes('all');
        }
      }
      return item;
    });
    return newConfig;
  }

  const initConfig = (initialDetails) => {
    const _configData = [...AUTO_UPDATE_CONFIG];
    for (const i in _configData) {
      const configName = _configData[i].name;
      if (!_configData[i].multiSelect) {
        // Set value to the first option if it's not a multi select
        const userValue = details[configName];
        const selectedOption = _configData[i].options.find(o => o.value === userValue)
        _configData[i].value = selectedOption ? selectedOption.value : _configData[i].options[0].value;
      } else {
        // Set value to an empty array if it's a multi select
        _configData[i].value = [];
        // Fill the options with website names
        for (const website of allWebsites) {
          _configData[i].options.push({
            value: website.slug,
            label: WebsiteHelper.getLabel(website),
          });
        }
      }
    }
    return maybeDisableConfigOptions(_configData, initialDetails);
  }

  const initDetails = (userConfig) => {
    for (const key of Object.keys(userConfig)) {
      if (!userConfig[key]) {
        const option = AUTO_UPDATE_CONFIG.find(option => option.name === key);
        if (!option) {
          window.logHelper.error(`Auto updater option ${key} not found as a valid option`, userConfig);
          continue;
        }
        userConfig[key] = option.default || (option.options && option.options[0].value) || null;
      }
    }
    return userConfig;
  }

  const initialDetails = initDetails(initialUserConfig);
  const [details, setDetails] = useState(initialDetails);
  const initialConfig = initConfig(initialDetails);
  const [configData, setConfigData] = useState(initialConfig);

  // Handlers

  const submitGlobalSettings = () => {
    const data = { config: { ...details } };
    setLoading(true);
    dispatch(updateAutoUpdaterNginxConfig(data))
      .then(() => dispatch(setGlobalSuccessMsg({ model: 'Auto updater configuration', action: 'updated', plural: true })))
      .finally(() => setLoading(false));
  }

  const onChange = (name, value) => setDetails(prev => {
    if (name === 'include_websites' && value.includes('all')) {
      value = ['all'];
    }
    const newDetails = { ...prev, [name]: value };
    setConfigData(maybeDisableConfigOptions(configData, newDetails));
    return newDetails;
  })

  return (
    UserHelper.isPartner() ? <div className='global-settings'>
      <TitleBar className='titlebar'>
        <TitleBar.Title breadcrumbs={breadcrumbs}>Auto Updater Config</TitleBar.Title>
        <TitleBar.Actions>
          {JsxHelper.createBackButton()}
        </TitleBar.Actions>
      </TitleBar>
      <p className='color-primary subheader'>
        Configure the policies for the Auto-Updater to run on your websites.
        You can set the schedule, the websites to include or exclude, and the type of updates to run.
      </p>
      <Content>
        <div style={{ maxWidth: '500px' }}>
          {configData.filter(item => !item.hide).map((item, index) =>
            <WPSForm.RowItem key={index} margin='0px'>
              <div className='display-inline-block'>
                {JsxHelper.createLabelWithTooltip(item.title, item.desc, 'display-inline-block')}
              </div>
              <WPSSelect
                selectClass='secondary-box-select'
                name={item.name}
                value={details[item.name]}
                options={item.options}
                onChange={(e) => {
                  const value = item.multiSelect ? (e.target.values || []).map(item => item.value) : e.target.value;
                  return onChange(item.name, value)}
                }
                sortOff={true}
                disabled={item.disabled}
                isMultiSelect={item.multiSelect}
                isSearchable={item.multiSelect || item.isSearchable}
              />
              {item.name === 'vulnerability_updates' && details[item.name] === 'selected' && details['websites_update_status'] === 'disabled' && <div className='input-comment-text warning-font-color'>
                {configData.find(i => i.name === 'websites_update_status').title} is disabled. This setting will not take effect.
              </div>}
            </WPSForm.RowItem>
          )}
        </div>
        <div className='action-buttons display-flex-nowrap margin-bottom-30' style={{display: 'flex'}}>
          {JsxHelper.createButton({
            label: 'Save',
            loading,
            onClick: submitGlobalSettings,
          })}
        </div>
      </Content>
    </div> : JsxHelper.createPageNotAvailable()
  );
};

export default AutoUpdates;
