import React, { Fragment } from 'react';
import env from 'config/env';
import ReactDOMServer from 'react-dom/server';
import { WPSButton, WPSIconBtn, WPSBubble, WPSDropdown } from 'styles/layout/buttons';
import { WPSInput, WPSCheckbox, WPSCheckboxLabel, WPSLabel, WPSToggle, WPSInputFix, WPSTextArea, ErrorMsg } from 'styles/layout/forms';
import StringHelper from 'helpers/string';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom';
import { FiFilter } from 'react-icons/fi';
import { WPSFilters } from 'styles/layout/lists';
import { isEmptyOrNull, getStatusColor, getStateColor, isNullOrUndefined, isFunction, isString } from 'helpers';
import DateHelper from 'helpers/date';
import Icon from 'components/layout/icon';
import { setGlobalInfoMsg } from 'store/global/globalActions';
import ReadMore from 'components/layout/readMore';
import { AnalyticsCard } from 'styles/website/profile';
import TableActions from 'components/wpstaq/WPSDataTable/WPSTableActions';
import TableHelper from 'helpers/table';
import dnsHelper from './dnszone';
import FirewallHelper from 'helpers/firewall';
import BackButton from 'components/website/BackButton/BackButton';
import AdvancedTabs from 'components/website/advanced/advancedTabs/advancedTabs';
import WebsiteHelper from './website';
import Stepper from 'react-stepper-horizontal';
import Tooltip from 'components/layout/tooltip';
import WPSSelect from 'components/wpstaq/WPSSelect/WPSSelect';
import WPSTableloader from 'components/wpstaq/WPSDataTable/WPSTableloader';
import { RingLoader } from 'styles/layout/loaders';
import { BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs';

/* Helpers */

const _getColorClass = (color) => {
  if (color === 'error') {
    color = 'danger';
  }
  return color ? `${color}-font-color` : '';
}

const _createTooltip = (id, text, html, allowEmpty) => {
  const props = { id }
  if (html) {
    props.html = html;
  }
  if (!text && allowEmpty) {
    return <ReactTooltip {...props} />
  }
  return text && <ReactTooltip {...props}>{text}</ReactTooltip>
}

const _guessResultColor = (status) => {
  if ([ 'succeeded', 'success' ].includes(status)) {
    return 'success';
  } else if ([ 'failed', 'error' ].includes(status)) {
    return 'danger';
  } else if ([ 'stopped' ].includes(status)) {
    return 'warning';
  }
  return 'primary'; // e.g. in_progress, running, ...
}

/* Constants */

const ARROW_SYMBOL = '→';
const ARROW_DESC_SYMBOL = '↓';
const BULLET_SYMBOL = '•';

/* Breadcrumb */

const createBreadcrumbs = (current, section, more, sectionLink) => {
  const breadcrumbs = [
    {
      text: 'Home',
      link: '/',
    },
    section ? {
      text: StringHelper.capitalizeFirstLetter(section),
      link: `/${sectionLink || section.toLowerCase()}`,
    } : false,
  ].filter(Boolean);
  if (more) {
    breadcrumbs.push(...more);
  }
  breadcrumbs.push({
    text: StringHelper.capitalizeFirstLetter(current),
  });
  return breadcrumbs;
}

/* Components */

const _createLabelString = (label) => isString(label) ? (label.endsWith(':') ? label : `${label}:`) : label;

const createLabel = (label, htmlFor, required) => <WPSLabel htmlFor={htmlFor} required={required}>{_createLabelString(label)}</WPSLabel>

const createTooltip = (id, text, html) => _createTooltip(id, text, html, true)

const createBasicTooltip = (text, place) => <Tooltip place={place || 'right'} text={text} />

const createLabelWithTooltip = (label, tooltip, labelClass, htmlFor) =>
  <Fragment>
    <WPSLabel className={labelClass || ''} htmlFor={htmlFor || ''}>{_createLabelString(label)}</WPSLabel>
    {createBasicTooltip(tooltip)}
  </Fragment>

const createRelativeLink = (link, text, customClass) => 
  <Link className={customClass || ''} to={link}>{text}</Link>

const createWebsiteLink = (website) =>
  website ? createRelativeLink(`/websites/${website.slug}/overview`, WebsiteHelper.getLabel(website)) : null

const copyToClipboard = async (dispatch, value, type, trimDot) => {
  value = trimDot ? StringHelper.trimRight(value, '.') : value;
  await navigator.clipboard.writeText(value);
  type = type || 'text';
  dispatch && dispatch(setGlobalInfoMsg({ text: `${StringHelper.capitalizeFirstLetter(type)} copied to clipboard.` }));
}

const createEventLogTable = (logs, timezone) => {
  logs = logs.map(log => {
    const color = log.message.includes('(warning)') ? 'warning-font-color' : log.message.includes('failed') ? 'danger-font-color' : '';
    return { ...log, color };
  });

  return <table className='eventlog-table'>
    <tbody>
      {logs.map((log, i) => <tr key={i}>
        <td className='eventlog-time'>{DateHelper.toHumanTimeWithSeconds(log.time, timezone)}</td>
        <td className={log.color}>{log.message}</td>
      </tr>)}
    </tbody>
  </table>
}

const createResultBox = (status, html, style) => {
  const color = _guessResultColor(status);
  const icon = color === 'primary' ? 'loading' : color;
  return <table className={`result-box ${_getColorClass(color)}`} style={style||{}}><tbody><tr>
    <td className='result-box-icon'>{icon === 'loading' ? JsxHelper.createBubble({ loading: true, }) : JsxHelper.createIcon({ icon })}</td>
    <td className='result-box-message'><div dangerouslySetInnerHTML={{ __html: html }}></div></td>
  </tr></tbody></table>
}

const createText = (data) => {
  let { text, prettify, classes, style, color } = data;
  prettify = prettify || true;
  classes = classes || '';
  style = style || {};
  color = color || '';
  if (color) {
    classes = classes + ` ${_getColorClass(color)}`;
  }
  text = prettify && text ? StringHelper.prettifyText(text) : text;
  return <span style={style} className={`wps-text-cell ${classes}`}>{text}</span>;
}

const createReadMoreText = (data) => {
  return <ReadMore
    customStyle={data.style || {}}
    text={data.text}
    limit={150}
  />
}

const createDropdownButton = (data) => {
  let { label, options, selected, onChange, classes } = data;
  classes = `ws-dropdown ${classes || ''}`;
  options = options.map(option => {
    const optionClass = `ws-dropdown-item ${option.class || ''}`;
    const optionKey = StringHelper.randomString();
    const signHTML = option.sign ? <span className='ws-dropdown-sign'>{option.sign}{' '}</span> : null;
    if (option.type === 'link' || option.link) {
      option.content = <div key={optionKey} className={optionClass}>
        {signHTML?signHTML:null}
        {JsxHelper.createRelativeLink(option.link, option.label)}
      </div>
    } else if (option.onClick) {
      option.content = <div onClick={option.onClick} key={optionKey} className={optionClass}>
        {signHTML?signHTML:null}
        <span className='ws-dropdown-content'>{option.label}</span>
      </div>
    } else {
      option.content = <div key={optionKey} className={optionClass}>
        <span className='ws-dropdown-content'>{option.content}</span>
      </div>
    }
    return option;
  });
  return <WPSDropdown
    className={classes}
    options={options}
    onChange={(option) => onChange ? onChange(option) : null}
    buttonLabel={label}
    selectedOption={selected || ''}
  />
}

const createBackButton = (path) =>
  path ? <BackButton path={path}/> : <BackButton/>

const createStepper = (steps, activeStep, disabledSteps) => <Stepper
  steps={steps}
  activeStep={activeStep}
  disabledSteps={disabledSteps}
  activeColor='#2196f3'
/>

const createCloseButton = (data) => <WPSButton
  className='close--btn'
  onClick={data.onClick}
  disabled={data.loading}> 
  Close
</WPSButton>

const createRefreshButton = (data) => <WPSButton
  className={data.className || 'refresh--btn'}
  onClick={data.onClick}
  loading={data.loading}
  disabled={data.disabled || data.loading}> 
  Refresh
</WPSButton>

const createLoadMoreButton = (data) => <WPSButton
  className={data.className || 'action-read--btn'}
  onClick={data.onClick}
  loading={data.loading}
  disabled={data.loading || data.disabled}> 
  Load More
</WPSButton>

const createCopyButton = (data) => {
  let { background, icon, color, label, type, tooltip, value, dispatch, style, small } = data;
  // Ensure value is a string
  if (!isString(value)) {
    window.logHelper.error('Value is not a string:', value);
    value = '';
  }
  // If value is IP address set type to "IP Address"
  if (!type && value && value.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)) {
    type = 'IP address';
  }
  small = small || false;
  icon = icon || 'copy';
  value = value || label;
  color = color || 'light';
  background = background || 'info';
  tooltip = tooltip || 'Copy to clipboard';
  type = type || 'text';
  return <WPSBubble
    className={small ? 'btn-xs' : ''}
    background={background}
    color={color}
    tooltip={tooltip}
    icon={icon}
    style={style || {}}
    display='block'
    onClick={async () => copyToClipboard(dispatch, value, type)}>
    {label || value}
  </WPSBubble>
}

const createButton = (data) => {
  const { key, label, type, onClick, loading, classes, disabled, tooltip, style, icon, linkTo, margin, small } = data;
  let _classes = `${classes || ''} ${small ? 'wps-button-small' : ''}`;
  if (disabled) {
    _classes = _classes + ' disabled--btn';
  } else if (!_classes.includes('--btn')) {
    const labelToColorMap = {
      warning: ['remove', 'unassign', 'write', 'warning', 'refresh', 'execute', 'cancel', 'clear', 'purge', 'revert', 'flush', 'retry', 'disconnect'],
      success: ['create', 'assign', 'save', 'upload', 'add', 'manage', 'connect'],
      primary: ['next', 'read', 'preview', 'notice', 'template', 'edit', 'update', 'check', 'primary', 'action', 'load', 'search'],
      info: ['back', 'prev', 'clipboard', 'history'],
      danger: ['delete', 'revoke', 'stop', 'fail'],
      alt: [ 'export' ],
    }
    for (const [color, words] of Object.entries(labelToColorMap)) {
      if (words.some(word => label.toLowerCase().includes(word))) {
        _classes = _classes + ` ${color}--btn`;
        break;
      }
    }
  }
  const button = <WPSButton
    key={key || StringHelper.randomSlug()}
    className={_classes}
    onClick={onClick}
    icon={icon || null}
    style={style || {}}
    disabled={disabled}
    loading={loading}
    margin={margin || null}
    type={type || 'button'}
    hover={disabled && !isEmptyOrNull(tooltip) ? tooltip : null}>
    {label}
  </WPSButton>
  return linkTo ? <Link to={linkTo}>{button}</Link> : button;
}

const createFilterIcon = (data) => {
  const { setShowFilters, showFilters, filters, onClickFilter, filtersRef, style } = data;
  return <WPSIconBtn
    onClick={() => setShowFilters(true)}
    style={{ position: 'relative', marginRight: data.marginRight || '0', ...style }}>
    <FiFilter />
    <WPSFilters
      toggle={filtersRef}
      show={showFilters}
      filters={filters}
      changeFilters={onClickFilter}
    />
  </WPSIconBtn>
}

const createIcon = (data) => {
  let { tooltip, icon, customClass, color, html, count, style, onClick } = data;
  const randId = StringHelper.randomSlug();
  const colorClass = color ? _getColorClass(color) : '';
  customClass = `${customClass || ''} ${colorClass} wps-icon`;
  customClass += onClick ? 'display-inline-block pointer': '';
  return <span
    className={customClass}
    style={style || {}}
    onClick={onClick}
    data-for={randId}
    data-tip={tooltip}>
    <Icon tag={icon} count={count} />
    {_createTooltip(randId, tooltip, html)}
  </span>
}

const createIconWithText = (data) =>
  <div className={(data.className||'') + (data.textColor ? ` ${data.textColor}-font-color` : '')}>
    {JsxHelper.createIcon({ customClass: 'display-inline-block', icon: data.icon, color: data.color || data.icon })}
    <span style={{
      marginLeft: '7px',
      position: 'relative',
      top: '-3px',
    }}>{data.text}</span>
  </div>

const createBubble = (data) => {
  let { background, icon, color, text, tooltip, customClass, margin, padding, sentence, small, style, onClick, loading } = data;
  tooltip = tooltip || '';
  style = style || {};
  text = text || '';
  background = background || '';
  color = color || 'light';
  customClass = `${customClass || ''} ${small ? 'wps-bubble-small' : ''}`;
  loading = loading || false;
  icon = icon || null;
  margin = margin || (icon ? '0' : null);
  padding = padding || (icon ? '0' : null)
  sentence = sentence || false;
  text = sentence ? StringHelper.capitalizeFirstLetter(text) : text;
  const randId = StringHelper.randomSlug();
  return (<Fragment key={randId}><WPSBubble
      display='inline'
      className={`${customClass} wps-bubble`}
      style={style}
      margin={margin}
      padding={padding}
      onClick={onClick}
      color={color}
      icon={icon}
      data-tip={tooltip}
      data-for={randId}
      loading={loading}
      background={background}>
      {text}
    </WPSBubble>
    {_createTooltip(randId, tooltip, true)}
    </Fragment>);
}

const createAdvancedTab = (e, index) => (
  !e.hide && <AdvancedTabs
    key={index || StringHelper.randomSlug()}
    customClass={e.customClass || ''}
    type={e.type}
    name={e.name}
    desc={e.desc}
    descColor={e.descColor ? e.descColor : null}
    descPrefix={e.descPrefix ? e.descPrefix : null}
    icon={e.icon}
    title={e.title}
    btnText={e.btnText}
    btnDisabled={e.btnDisabled}
    btnDisabledTip={e.btnDisabledTip}
    loading={e.loading ? e.loading : null}
    toolTipText={e.toolTipText ? e.toolTipText : null}
    secondBtn={e.secondBtn ? e.secondBtn : null}
    secondBtnLoading={e.secondBtnLoading}
    onClick={e.btn1Click}
    spinnerText={e.spinnerText}
    spinnerWait={e.spinnerWait}
    spinner={e.spinner}
    statusTooltip={e.statusTooltip}
    statusColor={e.statusColor}
    onSecondBtnClick={e.btn2Click}
  />
);

const createAnalyticsCards = (data) => 
  <AnalyticsCard className={data.customClass||''} style={data.customStyle || {}}>
    {data.items.map((item, i) => <li key={`ac${i}`} className='analytics-card-item'>
      {item.icon && <Icon tag={item.icon} />}
      <h4>{item.title}</h4>
      <span className='analytics-card-values'>{item.value}</span>
    </li>)}
  </AnalyticsCard>

/* Table Cells */

const createTableDateTimeCell = (date, timezone, includeSeconds) => createTableMultiLineCell({
  headerClass: 'wps-date-cell',
  header: DateHelper.toHumanDate(date, timezone), // date
  subheader: DateHelper.toHumanTime(date, timezone, null, includeSeconds), // time
})

const createTableWebsiteCell = (data) => {
  const { website, paddingRight, marginRight } = data;
  const style = {};
  if (paddingRight) {
    style.paddingRight = paddingRight;
  }
  if (marginRight) {
    style.marginRight = marginRight;
  }
  if (!website) {
    return createText({ text: 'Website not found', style });
  }
  return createTableMultiLineCell({
    header: WebsiteHelper.getLabel(website),
    headerLink: `/websites/${website.slug}/overview`,
    subheader: website.default_domain,
    subheaderLink: `//${website.default_domain}`,
    subheaderClass: 'inactive-font-color break-all',
    style
  })
}

const createTableHeaderCell = (data) => {
  let { header, headerClass, headerLink, headerStyle, prettify } = data;
  prettify = prettify || true;
  headerStyle = headerStyle || {};
  headerClass = `cell-header ${headerClass || ''} ${headerLink ? 'class-link' : ''}`;
  header = prettify && header ? StringHelper.prettifyText(header) : header;
  const isHeaderLinkFunction = isFunction(headerLink);
  const headerAbsLink = header && headerLink && !isHeaderLinkFunction && headerLink.startsWith('http');
  const headerRelLink = header && headerLink && !isHeaderLinkFunction && !headerAbsLink;
  return <div>
    {!headerLink && <span style={headerStyle} className={headerClass}>{header}</span>}
    {headerRelLink && <Link style={headerStyle} className={headerClass} to={headerLink}>{header}</Link>}
    {headerAbsLink && createLink(headerLink, header, headerClass, '', headerStyle)}
    {isHeaderLinkFunction && createClickableText(header, headerLink, headerClass)}
  </div>
}

const createTableMultiLineCell = (data) => {
  let { header, subheader, subheaderHTML, prettify, style, mainClass, headerClass, headerLink, headerStyle,
        subheaderClass, subheaderLink, subheaderLinkPrefix, subheaderPostHTML
  } = data;
  prettify = prettify || true;
  header = header || '';
  subheader = subheader || '';
  subheader = prettify && subheader ? StringHelper.prettifyText(subheader) : subheader;
  subheaderClass = `cell-subheader ${subheaderClass || ''} ${subheaderLink ? 'class-link' : ''}`
  style = style || {};
  if (header.length > 35 || subheader.length > 35) {
    style = {...style, paddingRight: '10px'};
  }
  subheaderLinkPrefix = subheaderLinkPrefix || '';
  const subheaderAbsLink = subheader && subheaderLink && (subheaderLink.startsWith('http') || subheaderLink.startsWith('//'));
  const subheaderRelLink = subheader && subheaderLink && !subheaderAbsLink;

  return (
    <div className={`${mainClass || ''} multiline-cell`} style={style}>
      {createTableHeaderCell({ header, headerClass, headerLink, headerStyle, prettify })}
      {subheader && !subheaderLink && <div className={subheaderClass}>{subheader}</div>}
      {subheaderHTML && <div className={subheaderClass}>{subheaderHTML}</div>}
      {subheaderRelLink && createRelativeLink(subheaderLink, subheader, subheaderClass)}
      {subheaderAbsLink && createLink(subheaderLink, subheader, subheaderClass, subheaderLinkPrefix)}
      {subheaderPostHTML && <div className={`${subheaderClass} subheader-post`}>{subheaderPostHTML}</div>}
    </div>
  );
}

const createTableCellMultiLinks = (data) => {
  data.style = data.style || {};
  if (data.circle) {
    data.style = {...data.style, listStyle: 'circle !important', listStyleType: 'circle !important' };
  }
  data.default = data.default || '-';
  return (!isEmptyOrNull(data.links) ? <ul className='multilinks-cell'>
    {data.links.map(item => (
      <li className='class-link' key={item.label}>
        <Link style={data.style} to={item.link}>{item.label}</Link>
      </li>
    ))}
  </ul> : <span className='none-label'>{data.default}</span>)
}

const createTableCellFromTo = (data) => {
  let { from, to, customClass, symbol, icon, iconColor, tooltip } = data;
  if (!from && to) {
    from = to;
    to = null;
  }
  symbol = symbol || ARROW_SYMBOL;
  const randId = tooltip && icon ? StringHelper.randomSlug() : null;
  return (
    <div className={`from-to-cell ${customClass || ''}`}>
      <span>{from}{to ? ' ' + symbol : ''}{to ? ' ' + to : ''}</span>
      {icon && <Fragment>
        <WPSBubble
          className='from-to-cell-icon'
          display='inline'
          padding='0'
          margin='0'
          color={iconColor || 'light'}
          icon={icon}
          data-for={randId}
          data-tip={tooltip}></WPSBubble>
        {_createTooltip(randId, tooltip)}
      </Fragment>}
    </div>
  );
}

const createBox = (data) => {
  data.style = data.style || {};
  data.style.fontSize = data.style.fontSize || '13px';
  data.style.marginTop = data.style.marginTop || 0;
  data.style.width = data.style.width || 'fit-content';
  data.content = data.content || '';
  data.level = ['warning', 'notice', 'error'].includes(data.level) ? data.level : 'warning';
  data.tooltip = data.tooltip || false;
  data.classes = `ws-box ${data.level}-box ${data.classes || ''}`
  data.content = (data.showLevel || false) ? <span><b>{data.level.toUpperCase()}:</b> {data.content}</span> : data.content;
  return <div className={data.classes} key={data.index || StringHelper.randomSlug()} style={data.style}>
    {data.content}
    {data.tooltip && createBasicTooltip(data.tooltip)}
  </div>; 
}

/* Table Headers */

const createTableActionsHeader = (actions, width) => ({
  name: '',
  width: width || null,
  button: true,
  allowOverflow: true,
  cell: row => <TableActions actions={actions.map(TableHelper.createActionItem)} item={row} />,
})

const createTableTimeHeader = (selector, timezone, width, label, defaultText) => ({
  name: (label || 'Time') + (!timezone? ' (UTC)': ''),
  selector: selector,
  width: width || '12%',
  sortable: true,
  cell: row => row[selector] ? createTableDateTimeCell(row[selector], timezone) : (defaultText || '-'),
})

const createTableTitleHeader = (selector, label, width, subtitleSelector, defaultText) => ({
  name: label,
  selector: selector,
  searchable: true,
  width: width || null,
  cell: row => {
    if (row[selector]) {
      const searchText = row[selector] + (row[subtitleSelector]?`:${row[subtitleSelector]}`:'');
      TableHelper.customizeCellValue(row, selector, searchText);
    }
    return row[selector] ? createTableMultiLineCell({
      header: row[selector],
      subheader: row[subtitleSelector] || '',
    }) : defaultText || '-';
  },
})

const createTableCountryFlagHeader = (selector, label, width) => ({
  name: label || 'Origin',
  selector: selector || 'origin',
  width: width || '7%',
  searchable: true,
  cell: row => FirewallHelper.displayCountryFlag(row),
});

const createTableWebsiteHeader = (allWebsites, width, style) => ({
  name: 'Website',
  selector: 'website',
  searchable: true,
  width: width || '25%',
  style: style || {},
  cell: (row) => {
    const website = allWebsites.find(w => w.slug === row.website_slug);
    return JsxHelper.createTableWebsiteCell({ website })
  }
})

const createTableTextHeader = (selector, label, width, defaultText, sortable) => ({
  name: label,
  selector: selector,
  width: width || null,
  searchable: true,
  sortable: sortable || false,
  cell: row => createText({ text: row[selector] || defaultText || '' }),
});

const createTableTextHeaderWithCallback = (selector, label, width, callback, sortBy, style) => ({
  name: label,
  selector: selector,
  searchable: true,
  sortable: sortBy === false ? false : true,
  width: width || '10%',
  sortableValue: sortBy || false,
  cell: callback,
  style: style || {},
})

const createTableLongTextHeader = (selector, label, width, defaultText) => ({
  name: label,
  selector: selector,
  width: width || '30%',
  cell: row => !row[selector]
    ? (defaultText || '-')
    : StringHelper.limit(row[selector], 250),
})

const createTableBinaryBubbleHeader = (selector, label, width, callback, noMargin, yesText, noText) => ({
  name: label,
  selector: selector,
  sortable: true,
  width: width || '10%',
  cell: row => createBubble({
    text: (!isNullOrUndefined(callback) ? callback(row) : row[selector]) ? (yesText || 'Yes') : (noText || 'No'),
    background: (!isNullOrUndefined(callback) ? callback(row) : row[selector]) ? 'success' : 'danger',
    small: true,
    customClass: !noMargin
      ? (label.length <= 8 ? 'margin-left-10-perc' : 'margin-left-25-perc')
      : 'margin-0',
  })
});

const createTableCopyButtonHeader = (dispatch, selector, label, width, background) => ({
  name: label,
  selector: selector,
  searchable: true,
  width: width || '10%',
  cell: row => createCopyButton({
    value: row[selector],
    type: label,
    background: background,
    dispatch,
  }),
})

const createTableStatusHeader = (selector, bgCallback, noMargin, width, label) => {
  label = label || (selector === 'state' ? 'State' : 'Status')
  selector = selector || 'status';
  bgCallback = bgCallback || (selector === 'state' ? getStateColor : getStatusColor);
  return {
    name: label || 'Status',
    selector: selector,
    searchable: true,
    width: width || '10%',
    cell: row => createBubble({
      text: StringHelper.toText(row[selector]),
      background: bgCallback(row[selector]),
      customClass: !noMargin ? 'margin-left-10-perc' : 'margin-0',
      tooltip: row.status_reason || row.status_message || row.status,
      small: true,
    })
  }
};

const createTablePeriodHeader = (fromSelector, toSelector, label, width, defaultTo) => ({
  name: label || 'Period',
  selector: fromSelector,
  searchable: true,
  width: width || '20%',
  cell: (row) => createTableCellFromTo({
    from: row[fromSelector],
    to: row[toSelector] || defaultTo || 'ongoing',
  })
});

const createTableHiddenHeader = (selector, label) => ({
  name: label,
  selector: selector,
  searchable: true,
  omit: true, // Used to hide the column from the table but still be able to filter by it.
})

const createTableTextListHeader = (selector, label, textCallback, width, defaultText, noCircle) => {
  let style = {};
  if (!noCircle) {
    style = {listStyleType: 'circle'};
  }
  return {
    name: label,
    selector: selector,
    searchable: true,
    width: width || '30%',
    cell: (row) => {
      const list = row[selector] || [];
      const listKeys = Array.isArray(list) ? list.map((v, k) => k) : Object.keys(list);
      return isEmptyOrNull(listKeys) ? (defaultText || '-') : <ul style={style}>
        {listKeys.map((key, index) => (
          <li style={{fontSize: '12px', listStyleType: 'inherit'}} key={index}>{textCallback ? textCallback(key, list[key]) : list[key]}</li>
        ))}
      </ul>
    },
  }
};

const createTableRequestStatusHeader = (selector, width) => createTableStatusHeader(
  selector,
  FirewallHelper.httpResponseToColor,
  FirewallHelper.httpResponseToText,
  width,
);

const createTableRequestTimeHeader = (selector, format, timezone) => ({
  name: 'Time' + (!timezone? ' (UTC)': ''),
  selector: selector,
  sortable: true,
  searchable: true,
  width: '13%',
  cell: row => {
    row = TableHelper.customizeCellValue(row, selector, '');
    row[`custom_${selector}`] = DateHelper.toHumanDateTime(row[selector], timezone, format, true);
    return createTableDateTimeCell(row[`custom_${selector}`], timezone, true);
  },
});

const createTableCreditCardTypeIconHeader = (selector, width) => {
  return {
    name: '',
    selector: selector || 'type',
    width: width || '8%',
    cell: (row) => createIcon({
      style: {fontSize: `${row.brand === 'amex' ? 30 : 25}px`},
      icon: ['amex', 'mastercard', 'visa'].includes(row.brand) ? row.brand : 'billing',
      tooltip: StringHelper.toText(row.brand),
    })
  }
}

const createTableHttpRequestHeader = (selector, paddingRight, width) => ({
  name: 'Request',
  selector: selector || 'request',
  searchable: true,
  width: width || '30%',
  style: {
    display: 'inline-block',
    paddingRight: paddingRight ? `${paddingRight}px !important` : null
  },
  cell: row => {
    let _method = row.method || '';
    let _uri = row.uri || row.argv || '';
    if (selector === 'Object') {
      _method = row.Operation;
      _uri = row.Object;
    } else if (selector === 'request') {
      const _request = row.request || '';
      _method = _request.split(' ')[0];
      _uri = _request.replace(new RegExp(" HTTP.*"), '').replace(_method, ' ');
    }
    return <div>
      {createBubble({
        text: `${_method}`,
        style: { fontSize: '12px' },
        small: true,
        customClass: 'margin-0',
        background: 'overlay',
      })}
      {createText({
        text: ` ${_uri}`,
        customClass: 'margin-0',
        style: { fontSize: '12px', marginLeft: '5px' },
      })}
    </div>
  },
})

const createTableDNSRecordsHeader = (dispatch, label, width, transformer) => ({
  name: label || 'DNS Records',
  selector: 'values',
  sortable: true,
  searchable: true,
  width: width || '30%',
  cell: row => 
  <ul>
    {(transformer ? transformer(row) : row).values
      .map(i => row.type === 'NS' ? dnsHelper.normalizeNSValue(i) : i)
      .map(i => <li key={i} style={{ display: 'flex', marginBottom: '8px' }}>
        {JsxHelper.createCopyButton({ label: i, type: 'Record value', dispatch })}
      </li>)}
  </ul>
})

/* Modals */

const createModalItems = (arr) => {
  return '<ul>' + arr.map(d => `<li class='modal-item'><span><b>${d}</b></span></li>`).join('') + '</ul>';
}

const createItemsList = (list, noStyle, className) => {
  className = (className || 'list-item') + (noStyle ? ' list-style-none' : '');
  return <ul>{
      list.map((item, i) => <li key={i} className={className}><span>{item}</span></li>)
  }</ul>
}

/* Links */

const createClickableText = (label, onClick, labelClass) =>
  <span className={`goto-link ${labelClass || ''}`} onClick={onClick}>{label}</span>

const createLink = (link, label, classes, prefix, style) => {
  label = label || link;
  classes = classes || '';
  style = style || {};
  link = link.startsWith('http') ? link : '//' + link;
  return <a className={`goto-link ${classes}`} target="__blank" href={link} style={style}>
    {prefix && <span className='goto-link-prefix'>{prefix}</span>}
    {label}
  </a>
}

const createFaqLink = (link, label) => {
  label = label || link;
  return <a className='goto-link subheader-link' target="__blank" href={env.getKnowledgeBaseUrl(link)}>{label}</a>
}

const createPlansLink = () => {
  return <a className='goto-link subheader-link' target="__blank" href={env.getBrandSiteUrl('/plans/')}>plans</a>
}

const createSubheaderLink = (link, label, _classes) => {
  label = label || link;
  _classes = 'subheader-link ' + (_classes || '');
  if (!link.startsWith('http://') && !link.startsWith('https://')) {
    link = '//' + link;
  }
  return <span className={_classes} onClick={() => window.open(link, '_blank').focus() }>{label}</span>
}

const createToolboxLink = (whois, label) =>
  <a className='goto-link' target="__blank" href={`https://mxtoolbox.com/SuperTool.aspx?action=whois:${whois}&run=toolpage`}>{label || 'WHOIS?'}</a>

/* Form */

const createToggleInput = (input) => {
  const toggle = <Fragment>
    <WPSToggle
      className={input.class || ''}
      type='checkbox'
      name={input.name}
      id={input.id || input.name}
      checked={input.checked}
      onChange={input.onChange}
      disabled={input.disabled || false}
    /><span>{input.label}</span>
  </Fragment>
  const _id = input.id || input.name;
  return <div className={input.wrapperClass || ''}>{
    !input.tooltip
      ? toggle
      : <div data-for={_id} data-tip={input.tooltip}>
          {toggle}
          {_id && <ReactTooltip id={_id} />}
        </div>
  }</div>
}

const createCheckbox = (input, index) =>
  <WPSCheckboxLabel key={input.name} disabled={input.disabled} className={input.class || ''} style={input.style || {}}>
    <WPSCheckbox
      key={index || StringHelper.randomSlug()}
      name={input.name}
      type='checkbox'
      id={input.id || input.value}
      checked={input.checked}
      onChange={input.onChange}
      disabled={input.disabled || false}
      ref={input.ref || null}
    />
    &nbsp; {input.label}
    {input.tooltip && <Tooltip text={input.tooltip} place='right' />}
  </WPSCheckboxLabel>

const createSelectInput = (input) => {
  let props = {
    isSearchable: input.isSearchable || input.multiSelect || false,
    name: input.name || '',
    value: input.value,
    options: input.options,
    onChange: input.onChange,
    tooltip: input.tooltip,
    sortOff: input.sortOff || false,
    selectClass: input.class || '',
    withGroups: input.withGroups || false,
    placeholder: input.placeholder,
    disabled: input.disabled || false,
    noneOption: input.noneOption || false,
    noneText: input.noneText || 'None',
  };
  if (input.multiSelect) {
    props = { ...props, isMultiSelect: true, closeMenuOnSelect: false, selectClass: 'select-input' };
  }
  return <Fragment>
    {input.label && <WPSLabel className={input.labelClass} required={input.required || false}>{input.label} {input.tooltip && createBasicTooltip(input.tooltip)}</WPSLabel>}
    <WPSSelect {...props} />
  </Fragment>
}

const createDateInput = (input) => {
  return <Fragment>
    {input.label && <WPSLabel required={input.required || false}>{input.label} {input.tooltip && createBasicTooltip(input.tooltip)}</WPSLabel>}
    <WPSInput
      type='date'
      min={input.min || '2020-01-01'}
      max={input.max || '2030-12-31'}
      name={input.name}
      value={input.value}
      onChange={input.onChange}
      disabled={input.disabled || false}
      placeholder={input.placeholder || 'Select date'}
    />
  </Fragment>
}

const createTextInput = (input, index) => {
  // For some reason, adding random index slows down the onChange event.
  const inputHtml = <WPSInput
    style={input.style || {}}
    key={index}
    name={input.name}
    type={input.type || 'text'}
    placeholder={input.placeholder}
    value={input.value}
    onChange={input.onChange}
    disabled={input.disabled || false}
    ref={input.ref || null}
    autoComplete={input.autoComplete || 'off'}
    className={input.class || ''}
  />;

  let output = inputHtml;

  if (input.suffix) {
    output = <WPSInputFix className='input-suffixed'>{inputHtml}<span className='suffix'>{input.suffix}</span></WPSInputFix>;
  } else if (input.setTogglePassword) {
    output = <WPSInputFix>
      {inputHtml}
      <button
        type='button'
        className='suffix'
        onClick={() => input.setTogglePassword(!input.togglePassword)}>
        {!input.togglePassword ? <BsFillEyeFill /> : <BsFillEyeSlashFill />}
      </button>
    </WPSInputFix>;
  }
  return <Fragment>
    {input.label && <WPSLabel className={input.labelClass || ''} required={input.required || false}>
      {input.label} {input.tooltip && createBasicTooltip(input.tooltip)}
      {input.errors && input.errors[input.name] && <ErrorMsg>{input.errors[input.name].message}</ErrorMsg>}
      {input.error && <ErrorMsg>{input.error}</ErrorMsg>}
    </WPSLabel>}
    {output}
  </Fragment>
}

const createTextareaInput = (input, index) => {
  const style = {width: '100%', resize: 'none', ...input.style || {}};
  const html = <WPSTextArea
    style={style}
    key={index}
    name={input.name}
    placeholder={input.placeholder}
    disabled={input.disabled || false}
    value={input.value}
    onChange={input.onChange}
    ref={input.ref || null}
    rows={input.rows || 5}
    required={input.required || false}
    className={input.class || ''}
  ></WPSTextArea>
  return <Fragment>
    {input.label && <WPSLabel className={input.labelClass || ''} required={input.required || false}>
      {input.label} {input.tooltip && createBasicTooltip(input.tooltip)}
      {input.errors && input.errors[input.name] && <ErrorMsg>{input.errors[input.name].message}</ErrorMsg>}
    </WPSLabel>}
    {html}
  </Fragment>
}

/* Boxes */

const createTipBox = (content, nolabel, prefix, style) => <div className='notice-box' style={style||{}}>
  {!nolabel && <span><b>{prefix || 'Tip'}:</b>{' '}</span>}
  <div className='box-content'>{content}</div>
</div>

const createWarningBox = (content, nolabel, prefix, style) => <div className='warning-box' style={style||{}}>
  {!nolabel && <span><b>{prefix || 'Warning'}:{' '}</b></span>}
  <div className='box-content'>{content}</div>
</div>

const createSuccessBox = (content, style) => <div className='success-box' style={style || {}}>
  <div className='box-content'>{content}</div>
</div>

/* Misc */

const createPageNotAvailable = () => 
  <div style={{ textAlign: 'center', paddingTop: '10%' }}>
    <h1 style={{marginBottom: '8px'}}>Page not available</h1>
    <p>Sorry, the page you are looking for is not available.</p>
  </div>

const createDetailsTable = (keyValuePairs, customClass) =>
  <table className={`details-table ${customClass || ''}`}>
    <tbody>
      {keyValuePairs.map((pair, i) => <tr key={i}>
        <th>{pair.label}</th>
        <td>{pair.value}</td>
      </tr>)}
    </tbody>
  </table>

const createLoadingSpinner = (text, size, margin, padding, textStyle) =>
  <div className='loading-spinner'>
    <RingLoader color='primary' size={size || 40} margin={margin || '16px 0'} padding={padding || ''} />
    {text && <span style={textStyle || {}}>{text}</span>}
  </div>

const createTableLoader = (text, classes) =>
  <div className={`${classes || ''}`}>
    <WPSTableloader />
    {text && <span style={{display:'block', textAlign: 'center'}}>{text}</span>}
  </div>

const convertToString = (object) => ReactDOMServer.renderToString(
  <React.Fragment>
    {object}
  </React.Fragment>
)

const JsxHelper = {
  ARROW_SYMBOL,
  ARROW_DESC_SYMBOL,
  BULLET_SYMBOL,
  /* Misc */
  createPageNotAvailable,
  createDetailsTable,
  copyToClipboard,
  createBackButton,
  createStepper,
  createModalItems,
  createItemsList,
  createLink,
  createRelativeLink,
  createClickableText,
  createTooltip,
  createBasicTooltip,
  createBreadcrumbs,
  createLabel,
  createLabelWithTooltip,
  createWebsiteLink,
  createFaqLink,
  createSubheaderLink,
  createToolboxLink,
  createPlansLink,
  createLoadingSpinner,
  createTableLoader,
  convertToString,
  createBox,
  createTableHeaderCell,
  createTableMultiLineCell,
  createBubble,
  createTableCellMultiLinks,
  createButton,
  createCopyButton,
  createCloseButton,
  createRefreshButton,
  createLoadMoreButton,
  createIcon,
  createIconWithText,
  createFilterIcon,
  createTableCellFromTo,
  createTableDateTimeCell,
  createText,
  createTableWebsiteCell,
  createReadMoreText,
  createDropdownButton,
  createAnalyticsCards,
  createAdvancedTab,
  createEventLogTable,
  createResultBox,
  /* Headers */
  createTableActionsHeader,
  createTableTimeHeader,
  createTableStatusHeader,
  createTablePeriodHeader,
  createTableLongTextHeader,
  createTableTitleHeader,
  createTableTextHeader,
  createTableTextHeaderWithCallback,
  createTableWebsiteHeader,
  createTableHiddenHeader,
  createTableTextListHeader,
  createTableCountryFlagHeader,
  createTableHttpRequestHeader,
  createTableRequestTimeHeader,
  createTableBinaryBubbleHeader,
  createTableRequestStatusHeader,
  createTableCreditCardTypeIconHeader,
  createTableCopyButtonHeader,
  createTableDNSRecordsHeader,
  /* Form */
  createCheckbox,
  createToggleInput,
  createSelectInput,
  createTextInput,
  createTextareaInput,
  createDateInput,
  /* Boxes */
  createTipBox,
  createWarningBox,
  createSuccessBox,
};

export default JsxHelper;
